import React from 'react';
import {
  NotificationHandle,
  NotificationLevel,
  NotificationManager,
} from './NotificationManager';
import { useMutationObserver } from './Subscribable';

export type NotificationFn = (
  message: string,
  description?: React.ReactNode,
  url?: string,
) => NotificationHandle;

export type NotificationArgs = Parameters<NotificationFn>;

export interface NotificationApi {
  success: NotificationFn;
  info: NotificationFn;
  warn: NotificationFn;
  error: NotificationFn;
  preserve: (
    level: NotificationLevel,
    ...args: NotificationArgs
  ) => NotificationHandle;
}

const globalNotificationManager = new NotificationManager();

const buildParams = (...args: NotificationArgs) => {
  const [message, description, url] = args;
  return {
    message,
    description,
    url,
  };
};

export function createNotificationApi(): NotificationApi {
  const manager = globalNotificationManager;

  return {
    success(...args: NotificationArgs) {
      return manager.add(NotificationLevel.SUCCESS, buildParams(...args));
    },
    info(...args: NotificationArgs) {
      return manager.add(NotificationLevel.INFO, buildParams(...args));
    },
    warn(...args: NotificationArgs) {
      return manager.add(NotificationLevel.WARN, buildParams(...args));
    },
    error(...args: NotificationArgs) {
      return manager.add(NotificationLevel.ERROR, buildParams(...args));
    },
    // add a notification that won't close after the default timeout
    preserve(level: NotificationLevel, ...args: NotificationArgs) {
      return manager.add(level, buildParams(...args), true);
    },
  };
}

export const useNotificationContents = () =>
  useMutationObserver(globalNotificationManager.sub);
