import { globalToken } from '@dao/ui-theming';
import { Popover as PopoverImpl } from 'antd';
import 'antd/es/popover/style';
import { ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Icons } from '../icon';

const {
  colorBgContainer,
  colorBgElevated,
  borderRadiusXL,
  colorBgElevatedContent,
} = globalToken;
const { paddingHorizontalAcpop, paddingVerticalPAcpop } =
  globalToken.ActionPopover;

export { type PopoverProps } from 'antd';
export const Popover = PopoverImpl;

export const PopoverStyle = createGlobalStyle`
body{
  .MuiPopover-paper {
    background-color: ${colorBgContainer} !important;
    border: 1px solid ${colorBgElevated} !important;
    padding: 7px 9px;
  }
  
  .ant-popover-content {
    border-radius: ${borderRadiusXL}px;
    background-color: ${colorBgElevatedContent};
  }
  
  .ant-popover-inner {
    background-color: ${colorBgElevatedContent};
    border-radius: ${borderRadiusXL}px;
    padding: 0 !important;
  }
  
  .ant-popover-inner-content {
    padding: ${paddingVerticalPAcpop}px ${paddingHorizontalAcpop}px !important;
  }
  
  .ant-popover-arrow-content {
    --antd-arrow-background-color: ${colorBgElevatedContent} !important;
  }

  .MuiMenuItem-root {
      border-radius: ${globalToken.Select.borderRadiusSelectMenuRoot}px;
    }
    .MuiMenu-paper {
      border-radius: ${globalToken.Select.borderRadiusSelectMenuRoot}px !important;
      margin-top: ${globalToken.Select.marginTopSelectMenu}px;
    }
}
`;

//  React.FC<{ content: React.ReactNode }> = ({
//   children,
//   content,
// }) => {
//   return (
//     <PopupState variant="popover" popupId="demo-popup-popover">
//       {popupState => (
//         <div>
//           <div {...bindTrigger(popupState)}>{children}</div>
//           <PopoverImpl
//             {...bindPopover(popupState)}
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'center',
//             }}
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'center',
//             }}
//           >
//             {content}
//           </PopoverImpl>
//         </div>
//       )}
//     </PopupState>
//   );
// };

export function InfoPopover({ note }: { note: ReactNode }) {
  return (
    <Popover content={note} visible>
      <Icons.Info c2 mr={6} ml={6} />
    </Popover>
  );
}
