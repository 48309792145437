/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import { globalToken } from '@dao/ui-theming';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SelectImpl from '@mui/material/Select';
import cx from 'classnames';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import {
  getDefaultDataKey,
  SelectableProps,
  STYLE_PREFIX,
  useSelectableKey,
} from '../common';
import { Icons } from '../icon';
import {
  InputActivatedStyle,
  InputBaseStyle,
  InputBorderlessStyle,
  InputStandardStyle,
} from '../input/shared-styles';
import { Txt } from '../txt';

const {
  transition,
  colorTextBase,
  colorBgContainer,
  colorTextSecondary,
  fontFamily,
} = globalToken;

function ExpandMore({ open }: { open: boolean }) {
  return (
    <span
      style={{
        position: 'absolute',
        right: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        // to let click events pass by
        pointerEvents: 'none',
        fontSize: '0.8rem',
      }}
    >
      <Icons.Angle direction={open ? 'up' : 'down'} />
    </span>
  );
}

const SelectWrapper = styled(FormControl)<{ withLabel?: boolean }>`
  margin: 0;
  height: 100%;
  margin-top: ${p => (p.withLabel ? '0.75rem' : undefined)};
  .MuiInputBase-input {
    height: 100% !important;
  }
`;

const { colorInputLabel, colorInputLabelActive } = globalToken.Input;
const SelectLabel = styled(InputLabel)<{ isplaceholder?: number }>`
  && {
    font-size: 0.875rem;
    font-weight: ${p => (!p.isplaceholder ? 500 : 400)};
    font-family: ${fontFamily};
    color: ${p =>
      !p.isplaceholder ? colorInputLabel : colorTextSecondary};
    line-height: 1;
    transform: ${p =>
      !p.isplaceholder
        ? 'translate(0.25rem, -1.2rem)'
        : 'translate(0.8rem, 0.9rem) scale(1.1)'};
    &.Mui-focused {
      color: ${p =>
        !p.isplaceholder ? colorInputLabelActive : colorTextSecondary};
    }
    &[data-shrink='true'] {
      ${p => (p.isplaceholder ? 'display: none;' : 'opacity: 1')}
    }
  }
`;

const SelectInner = styled(SelectImpl)<{
  plain?: boolean;
  borderless?: number;
  placeholder?: string;
  value?: string;
  background?: string;
}>`
  && {
    border: none;
    height: 100%;
    min-width: ${p =>
      !p.value && p.placeholder && `${p.placeholder.length * 0.6}em`};
    .MuiSelect-select {
      ${p => (p.borderless ? InputBorderlessStyle : undefined)}
      ${p => (p.plain ? InputBaseStyle : InputStandardStyle)}
      color: ${colorTextBase};
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 24px;
      ${p =>
        p.background
          ? `background-color: ${p.background} !important;`
          : ''}
      &:hover {
        ${p =>
          p.plain
            ? `
          background-color: ${colorBgContainer};
          transition: background-color ${transition};
        `
            : ``}
      }
    }
    &.Mui-focused > .MuiSelect-select {
      ${InputActivatedStyle}
      border: 1px solid ${p =>
        p.plain || p.borderless
          ? 'none'
          : globalToken.Input.colorInputBorderActive} !important;
    }
    // overwrites MUI outline
    > fieldset {
      border: none;
    }
  }
`;

type SelectMeta = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  /** hide select outline in plain mode */
  plain?: boolean;
  borderless?: boolean;
  className?: string;
  style?: React.CSSProperties;
  background?: string;
  menuItemStyle?: React.CSSProperties;
};

/**
 * Select props for data type `T`.
 * `stringify` calcaulates data key, otherwide simple value / reference equivalence is used.
 */
export type SelectProps<T extends any[]> = SelectMeta & SelectableProps<T>;

function SelectWithOptions<T extends any[]>(props: SelectProps<T>) {
  const {
    label,
    options,
    renderOption,
    keyExtractor = getDefaultDataKey,
    placeholder,
    plain,
    background,
    borderless,
    menuItemStyle,
  } = props;
  const [selectedKey, updateSelectedKey] = useSelectableKey(props);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {/* MUI does not support placeholder in Select, we place it at label as an alternative */}
      {label && <SelectLabel>{label}</SelectLabel>}
      {placeholder && (
        <SelectLabel isplaceholder={placeholder ? 1 : 0}>
          {placeholder}
        </SelectLabel>
      )}
      <SelectInner
        value={selectedKey}
        label={label}
        placeholder={placeholder}
        plain={plain}
        borderless={borderless ? 1 : 0}
        background={background}
        IconComponent={() => <ExpandMore open={open} />}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={() =>
          renderOption(props.value, {
            isOptionInList: false,
            closeSelect: () => setOpen(false),
          })
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transitionDuration: 100,
        }}
      >
        {options.map(data => {
          const key = keyExtractor(data);
          return (
            <MenuItem
              key={key}
              value={key}
              onClick={() => {
                updateSelectedKey(data, key);
              }}
              style={menuItemStyle}
            >
              <Txt style={{ width: '100%' }}>
                {renderOption(data, {
                  isOptionInList: true,
                  closeSelect: () => setOpen(false),
                })}
              </Txt>
            </MenuItem>
          );
        })}
      </SelectInner>
    </>
  );
}

function SelectSkelton({ label, placeholder }: SelectMeta) {
  return (
    <>
      <SelectLabel>{label}</SelectLabel>
      <SelectInner disabled placeholder={placeholder} />
    </>
  );
}

export function Select<B extends boolean>(
  props: { disabled: B } & SelectMeta,
): ReactElement;
export function Select<T extends any[], B extends boolean>(
  props: { disabled?: B } & SelectProps<T>,
): ReactElement;
export function Select<T extends any[]>({
  style,
  ...props
}: SelectMeta | SelectProps<T>) {
  return (
    <SelectWrapper
      className={cx(`${STYLE_PREFIX}-select`, props.className)}
      fullWidth
      style={style}
    >
      {props.disabled ? (
        <SelectSkelton {...props} />
      ) : (
        <SelectWithOptions<T> {...(props as SelectProps<T>)} />
      )}
    </SelectWrapper>
  );
}
