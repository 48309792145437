// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  return {
    routes: {"1":{"path":"/","parentId":"@@/global-layout","id":"1"},"2":{"path":"/create-dao","parentId":"@@/global-layout","id":"2"},"3":{"path":"/governance-nft","parentId":"@@/global-layout","id":"3"},"4":{"path":"/dao/:id","parentId":"@@/global-layout","id":"4"},"5":{"path":"/dao/:id/actions","parentId":"@@/global-layout","id":"5"},"6":{"path":"/dao/:id/proposal/:id","parentId":"@@/global-layout","id":"6"},"7":{"path":"/dao/:id/create-proposal","parentId":"@@/global-layout","id":"7"},"8":{"path":"/*","parentId":"@@/global-layout","id":"8"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}},
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__daos" */'@/pages/daos.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__create-dao" */'@/pages/create-dao.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__governance-nft" */'@/pages/governance-nft.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__dao-details" */'@/pages/dao-details.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__dao-actions" */'@/pages/dao-actions.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__proposal-details" */'@/pages/proposal-details.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__create-proposal" */'@/pages/create-proposal.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/vercel/path0/packages/dao-web/src/layouts/index.tsx')),
},
  };
}
