import React from 'react';
import {
  createSubscribable,
  SubHandler,
  Subscribable,
} from './Subscribable';

export enum NotificationLevel {
  UNKNOWN = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  SUCCESS = 4,
}

export interface NotificationParams {
  message: string;
  description: string | React.ReactNode;
  url?: string;
}

export interface Notification {
  level: NotificationLevel;
  params: NotificationParams;
  timestamp: number;
  visible: boolean;
  handle: NotificationHandle;
  persist: boolean;
}

export interface NotificationHandle {
  close: () => void;
  update: (
    params: Partial<NotificationParams>,
    level?: NotificationLevel,
  ) => void;
}

export class NotificationManager implements Subscribable<Notification[]> {
  private notifications: Notification[] = [];

  sub: SubHandler<Notification[]>;

  constructor() {
    [this.getAll, this.add, this.sub] = createSubscribable(
      this.getAll.bind(this),
      this.add.bind(this),
    );
  }

  getAll(): Notification[] {
    return this.notifications;
  }

  add(
    level: NotificationLevel,
    params: NotificationParams,
    persist: boolean = false,
  ) {
    const manager = this;
    const notification: Notification = {
      level,
      params,
      timestamp: new Date().getTime(),
      visible: true,
      handle: {
        close() {
          notification.visible = false;
          notification.persist = false;
          manager.sub.publishUpdate();
        },
        update(paramUpdates, newLevel) {
          const newParams = {
            ...notification.params,
            ...paramUpdates,
          };
          notification.params = newParams;
          if (newLevel !== undefined) {
            notification.level = newLevel;
          }
          manager.sub.publishUpdate();
        },
      },
      persist,
    };
    this.notifications.push(notification);
    manager.sub.publishUpdate();

    if (!persist) {
      setTimeout(() => notification.handle.close(), 5000);
    }

    return notification.handle;
  }
}
