/* eslint-disable jsx-a11y/label-has-associated-control */
import { globalToken } from '@dao/ui-theming';
import { isError, isString } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { IconQuestionCircleOutlined } from '../icon';
import { CenterRow, Col } from '../layout';
import { Popover } from '../popover';
import { Txt } from '../txt';

export interface FormItemProps {
  label?: ReactNode;
  error?: Error | ReactNode;
  fieldId?: string;
  help?: ReactNode;
  helpIcon?: ReactNode;
  setDefaultVal?: () => void;
  defaultValPrompt?: ReactNode;
  children?: ReactNode;
  styles?: Partial<
    Record<
      | 'root'
      | 'label'
      | 'help'
      | 'helpPopover'
      | 'error'
      | 'defaultValPrompt',
      CSSProperties
    >
  >;
}
export const FormItem = ({
  children,
  fieldId,
  label,
  error: errorMsg,
  help,
  helpIcon,
  setDefaultVal,
  defaultValPrompt,
  styles = {},
}: FormItemProps) => {
  return (
    <ItemContainer fullWidth style={{ ...styles.root }}>
      {label && (
        <label
          htmlFor={fieldId}
          style={{
            marginLeft: 2,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center',
            gap: 7,
            ...styles.label,
          }}
        >
          {isString(label) ? (
            <Txt h2 bold>
              {label}
            </Txt>
          ) : (
            label
          )}
          {help && (
            <Popover
              content={
                isString(help) ? (
                  <Txt h2 style={styles.help}>
                    {help}
                  </Txt>
                ) : (
                  help
                )
              }
              overlayInnerStyle={{
                width: 240,
                minHeight: 72,
                ...styles.helpPopover,
              }}
            >
              {helpIcon ?? (
                <IconQuestionCircleOutlined
                  className="icon-form-item-help"
                  style={{ width: 14, height: 14 }}
                />
              )}
            </Popover>
          )}
        </label>
      )}
      <CenterRow>{children}</CenterRow>
      {errorMsg && isString(errorMsg) ? (
        <Txt h1 bold critical style={styles.error} ml={2} mt={4}>
          {errorMsg}
        </Txt>
      ) : isError(errorMsg) ? (
        <Txt h1 style={styles.error} mt={4}>
          {errorMsg.message}
        </Txt>
      ) : (
        errorMsg
      )}
      {defaultValPrompt && (
        <CenterRow
          onClick={() => setDefaultVal?.()}
          style={styles.defaultValPrompt}
          ml={2}
          mt={4}
        >
          {isString(defaultValPrompt) ? (
            <Txt
              color="colorPrimary"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                width: 'fit-content',
              }}
            >
              {defaultValPrompt}
            </Txt>
          ) : (
            defaultValPrompt
          )}
        </CenterRow>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled(Col)`
  .icon-form-item-help {
    color: ${globalToken.colorTextQuaternary};

    &:hover {
      color: ${globalToken.colorTextTertiary};
    }
  }
`;
