import { globalToken } from '@dao/ui-theming';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { CenterRow, View } from '../layout';
import { Txt } from '../txt';
import {
  InputActivatedStyle,
  InputBorderlessStyle,
  InputDisabledStyle,
  InputErrorStyle,
  InputStandardStyle,
} from './shared-styles';

const { colorInputLabel, colorInputLabelActive } = globalToken.Input;

export type AlignInputOpt = 'left' | 'right' | 'center';

export const InputBox = styled(CenterRow)<{
  borderless?: boolean;
  alignInput?: AlignInputOpt;
  inline?: boolean;
  focused?: boolean;
  error?: boolean;
  disabled?: boolean;
}>`
  ${p => [
    InputStandardStyle,
    p.borderless && InputBorderlessStyle,
    p.inline && 'padding: 0.2em;',
    p.focused && InputActivatedStyle,
    p.error && InputErrorStyle,
    p.disabled && InputDisabledStyle,
  ]}
  width: ${p => (p.inline ? undefined : '100%')};
  justify-content: ${p =>
    p.alignInput === 'center' ? 'center' : undefined};
`;

export const InputLabel = styled(Txt).attrs({
  h2: true,
  bold: true,
  className: `${STYLE_PREFIX}-input-label`,
})<{ focused?: boolean }>`
  color: ${p => (p.focused ? colorInputLabelActive : colorInputLabel)};
`;

export const InputWrapper = styled(View)<{
  inline?: boolean;
  disabled?: boolean;
}>`
  display: ${p => p.inline && 'inline-flex'};
  vertical-align: ${p => p.inline && 'middle'};
  width: ${p => !p.inline && '100%'};
  &:hover {
    cursor: ${p => (p.disabled ? 'not-allowed' : 'auto')};
  }
`;

export type InputProps = {
  // dispaly contents
  value?: string;
  label?: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  placeholder?: string;
  autoFocus?: boolean;

  // callbacks
  onChange?: (input: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  // customizations
  className?: string;
  disabled?: boolean;
  error?: boolean | Error;
  /** input alignment option (effective at block mode):
   *  - center: centering prefix, input and suffix, input will take space as its content requires
   *  - right: make the input flex and align input text to the right, suitale for number input
   *  - left(default): make the input flex and align input text left, suitable for text input
   */
  alignInput?: AlignInputOpt;
  inline?: boolean;
  borderless?: boolean;
  inputStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
};
