import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as Checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as Cross } from '../assets/icons/cross.svg';
import { ReactComponent as Earth } from '../assets/icons/earth.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as Exclamation } from '../assets/icons/exclamation.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Setting } from '../assets/icons/setting.svg';
import { ReactComponent as Success } from '../assets/icons/success.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as MaxInner } from '../assets/vectors/max.svg';
import { ReactComponent as Plus } from '../assets/vectors/plus.svg';
import { ReactComponent as SwitchInner } from '../assets/vectors/switch.svg';
import Angle from './special-icons/angle';
import Loading from './special-icons/loading';

import {
  CircleIcon,
  standardizeImg,
  standardizeSvg,
  TxtIcon,
} from './wrappers';

export const Icons = {
  // Standard Txt Icons
  Checkmark: TxtIcon(Checkmark),
  Cross: TxtIcon(Cross),
  Info: TxtIcon(Info),
  Plus: standardizeSvg(Plus, { viewBox: '0 0 14 14' }),
  Exclamation: TxtIcon(Exclamation),
  Success: TxtIcon(Success, { viewBox: '0 0 58 58' }),
  Error: TxtIcon(Error),
  Warning: TxtIcon(Warning),
  ArrowRight: TxtIcon(ArrowRight),
  CircleMax: TxtIcon(CircleIcon(MaxInner)),
  CircleSwitch: TxtIcon(CircleIcon(SwitchInner)),
  Setting: TxtIcon(Setting),
  Earth: TxtIcon(Earth),
  // Special Icons
  Loading,
  Angle,
};

// utility wrapper for external assets
export const IconWrappers = {
  standardizeSvg,
  standardizeImg,
};

export {
  ArrowUpOutlined as IconArrayUpOutlined,
  CheckCircleOutlined as IconCheckCircleOutlined,
  CheckOutlined as IconCheckOutlined,
  CheckSquareFilled as IconCheckedSquareFilled,
  CloseCircleOutlined as IconCloseCircleOutlined,
  CloseOutlined as IconCloseOutlined,
  CloseSquareFilled as IconCloseSquareFilled,
  CopyOutlined as IconCopyOutlined,
  DownOutlined as IconDownOutlined,
  HomeFilled as IconHomeFilled,
  HomeOutlined as IconHomeOutlined,
  InfoCircleOutlined as IconInfoCircleOutlined,
  LinkOutlined as IconLinkOutlined,
  MenuOutlined as IconMenuOutlined,
  PauseOutlined as IconPauseOutlined,
  PlaySquareOutlined as IconPlayCircleOutlined,
  QuestionCircleOutlined as IconQuestionCircleOutlined,
  RadiusSettingOutlined as IconSettingOutlined,
  SearchOutlined as IconSearchOutlined,
  SwapOutlined as IconSwapOutlined,
} from '@ant-design/icons';
