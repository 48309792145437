import { useParseNumberInput, type NumberType } from '@dao/shared/hooks';
import Big from 'big.js';
import { Input, InputProps } from './input';

export type NumeralInputProps<T extends NumberType> = {
  value: T | undefined;
  onChange: (input: T) => void;
  min?: T;
  max?: T;
  valueType: 'number' | 'big';
  precision?: number;
} & Omit<InputProps, 'value' | 'onChange'>;

/** Limitation: only supports non-negative number for now */
export function NumeralInput<T extends number | Big>({
  value,
  onChange: handleChange,
  max,
  min,
  alignInput = 'right',
  valueType,
  precision,
  ...inputProps
}: NumeralInputProps<T>) {
  const {
    value: strValue,
    onChange: onStrChange,
    error: violationError,
  } = useParseNumberInput({
    value,
    onChange: handleChange,
    min,
    max,
    valueType,
    precision,
  } as any);

  return (
    <Input
      value={strValue}
      onChange={onStrChange}
      error={violationError}
      alignInput={alignInput}
      {...inputProps}
    />
  );
}
