import { createGlobalStore } from '@dao/shared/deps';
import { startCase } from 'lodash';
import { useWallet as useWalletImpl } from './hook';

export const [useWalletAdapter, getWalletAdapter] = createGlobalStore(
  () => {
    const { walletCtx, walletCtxRef, setWalletModalShow, disconnect } =
      useWalletImpl();

    const getAddressWithNotify = () => {
      const address = walletCtxRef.current?.address;

      if (!address) {
        throw new Error('Please connect your wallet');
      }

      return address;
    };

    return {
      walletAddress: walletCtx?.address,
      walletIcon: walletCtx?.adapter.wallet?.icon,
      walletPublicKey: walletCtx?.adapter?.account?.publicKey?.toString(),
      signAndSubmitTransaction: (...params: any[]) => {
        const adapter = walletCtxRef.current?.adapter;
        if (!adapter) {
          throw new Error('Please connect your wallet');
        }

        // @ts-ignore
        return adapter.signAndSubmitTransaction(...params) as any;
      },
      getAddressWithNotify,
      getAddress: () => {
        return walletCtxRef.current?.address;
      },
      validNetworkWithNotify: (networkName: string | undefined) => {
        if (
          networkName?.toLowerCase() !==
          walletCtx?.adapter?.network?.name?.toLowerCase()
        ) {
          throw new Error(
            `Please switch your wallet to ${startCase(
              networkName ?? '',
            )}!`,
          );
        }
      },
      connect: () => setWalletModalShow(true),
      disconnect,
    };
  },
);
