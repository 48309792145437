import toArray from 'rc-util/lib/Children/toArray';
import React, { useEffect, useState } from 'react';
import { STYLE_PREFIX } from '../common';
import { CollapsePanel } from './CollapsePanel';

export type ExpandIconPosition = 'start' | 'end';
export interface CollapseProps {
  /** 当前展开的collapse的key */
  activeKey?: React.Key | React.Key[];
  /** 默认展开的key */
  defaultActiveKey?: React.Key | React.Key[];
  /* 手风琴效果 */
  accordion?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onChange?: (key: React.Key | React.Key[]) => void;
}

export const Collapse = ({
  style,
  children,
  activeKey,
  defaultActiveKey,
  accordion = false,
  onChange,
}: CollapseProps) => {
  const [curKeys, setCurKeys] = useState(
    getActiveKeysArray(defaultActiveKey ?? []),
  );

  useEffect(() => {
    if (activeKey) {
      setCurKeys(getActiveKeysArray(activeKey));
    }
  }, [activeKey]);

  const handleClickItem = (key: string) => {
    if (accordion) {
      setCurKeys(curKeys[0] === key ? [] : [key]);
    } else {
      const newKeys = [...curKeys];
      const index = newKeys.indexOf(key);
      if (index !== -1) {
        newKeys.splice(index, 1);
      } else {
        newKeys.push(key);
      }
      setCurKeys(newKeys);
    }
    onChange?.(key);
  };

  const getItems = () =>
    toArray(children).map((child: React.ReactElement, index) => {
      const key = `${child.key ?? index}`;

      const props = {
        key,
        isActive: curKeys.indexOf(key) > -1,
        onClick: () => handleClickItem(key),
        ...child.props,
      };

      return React.cloneElement(child, props);
    });

  return (
    <div
      className={`${STYLE_PREFIX}-collapse`}
      style={style}
      role={accordion ? 'tablist' : undefined}
    >
      {getItems()}
    </div>
  );
};

const getActiveKeysArray = (activeKey: React.Key | React.Key[]) => {
  let currentActiveKey = activeKey;
  if (!Array.isArray(currentActiveKey)) {
    const activeKeyType = typeof currentActiveKey;
    currentActiveKey =
      activeKeyType === 'number' || activeKeyType === 'string'
        ? [currentActiveKey]
        : [];
  }
  return currentActiveKey.map(key => `${key}`);
};

Collapse.Panel = CollapsePanel;
