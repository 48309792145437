import { globalToken } from '@dao/ui-theming';
import SliderImpl from '@mui/material/Slider';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { View } from '../layout';

export type SliderProps = {
  min?: number;
  max?: number;
  value: number;
  onChange: (v: number) => void;
  marks?: { label: React.ReactNode; value: number }[];
  background?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

const SliderInner = styled(SliderImpl)(
  (props: { background?: string }) => `
  .MuiSlider-track {
    background: ${props.background}
  }
`,
);

// WIP: style completion
export const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  min = 0,
  max = 100,
  marks = false,
  background,
  style,
  disabled,
}) => (
  <SliderContainer fullWidth className={`${STYLE_PREFIX}-slider`}>
    <SliderInner
      value={value}
      min={min}
      max={max}
      background={background}
      onChange={(e, v) => {
        onChange(v as number);
      }}
      components={{
        Thumb: props => <span className="MuiSlider-thumb" {...props} />,
        // Mark: props => <span className="MuiSlider-mark" {...props}></span>,
      }}
      marks={marks}
      style={{ ...style }}
      disabled={disabled}
    />
  </SliderContainer>
);

const { colorTextSecondary } = globalToken;
const {
  colorSliderRail,
  colorSliderTrack,
  colorSliderThumb,
  colorSliderMark,
  colorSliderThumbBorder,
} = globalToken.Slider;

export const SliderContainer = styled(View)`
  .MuiSlider-root {
    padding: 1px 2px !important;
    height: 6px !important;
  }

  .MuiSlider-rail {
    background-color: ${colorSliderRail} !important;
    height: 6px;
    border-radius: 6px;
    margin-right: 1px;
  }

  .MuiSlider-track {
    background: ${colorSliderTrack};
    border: none !important;
    margin-left: 1px;
    .MuiSlider-markLabel {
      background-color: red;
    }
  }

  .MuiSlider-thumb {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 81px;
    background: ${colorSliderThumb};
    box-shadow: 0px 0px 0px 1px rgba(7, 7, 29, 0.03),
      0px 1px 10px -2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
    margin-left: -8px;
    margin-top: -5px;
    display: flex;
    padding-right: 8px;
    align-items: center;
  }

  .MuiSlider-mark {
    width: 6px !important;
    height: 6px !important;
    border-radius: 20px !important;
    background-color: ${colorSliderMark} !important;
  }

  .MuiSlider-markLabel {
    color: ${colorTextSecondary} !important;
    top: 14px !important;
    margin-left: 3px;
  }

  .MuiSlider-thumb-line {
    width: 8px;
    height: 0px;
    left: 7px;
    top: 14px;

    border: 1px solid ${colorSliderThumbBorder};
    transform: rotate(-90deg);
  }
`;
