import { useLatest } from '@dao/shared/hooks';
import cs from 'classnames';
import { get, isFunction, isNumber } from 'lodash';
import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { STYLE_PREFIX } from '../common';
import { Icons } from '../icon';
import { CenterRow, Col, RotatableView } from '../layout';
import { Breakpoint, useShowScreens } from '../responsive';
import { Txt } from '../txt';
import { TableContainer } from './styled-container';

export type SortOrder = 'default' | 'ascend' | 'descend';

type SortFn<T> = (a: T, b: T) => number;
type MultipleSorter<T> = { multiple: number; compare?: SortFn<T> };

export type TableColumnConfig<T> = {
  field?: keyof T;
  key?: string;
  align?: 'left' | 'center' | 'right';
  title: string | React.ReactNode;
  render?: (
    value: any | undefined,
    item: T,
    index: number,
  ) => React.ReactNode;
  sorter?: SortFn<T> | { multiple?: number; compare?: SortFn<T> };
  defaultSortOrder?: SortOrder;
  style?: React.CSSProperties;
  responsive?: Breakpoint[];
};
export declare type ExpandedRowRender<DataType> = (
  record: DataType,
  index: number,
) => React.ReactNode;

export interface ExpandableConfig<DataType> {
  expandedRowKey?: React.Key;
  defaultExpandedRowKey?: React.Key;
  expandedRowRender: ExpandedRowRender<DataType>;
}

export type TableProps<DataType> = {
  data: DataType[];
  columns: TableColumnConfig<DataType>[];
  className?: string;
  style?: React.CSSProperties;
  onRowClick?: (item: DataType, index: number) => void;
  keyExtract: (data: DataType) => string;
  renderEmpty?: React.ReactNode;
  headerStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  expandable?: ExpandableConfig<DataType>;
  fullWidth?: boolean;
  ascendIcon?: ReactNode;
  desendIcon?: ReactNode;
};

export function Table<DataType>({
  columns,
  data,
  className,
  style,
  onRowClick,
  keyExtract,
  renderEmpty,
  headerStyle,
  bodyStyle,
  rowStyle,
  containerStyle,
  expandable,
  fullWidth = false,
  ascendIcon,
  desendIcon,
}: TableProps<DataType>) {
  const { expandedRowKey, expandedRowRender, defaultExpandedRowKey } =
    expandable ?? {};
  const [curKey, setCurKey] = useState(defaultExpandedRowKey);
  const [sorterKeys, setSorterKeys] = useState<number[]>([]);
  const [sortDirs, setSortDirs] = useState<SortOrder[]>([]);
  const showScreens = useShowScreens();

  const screenShowMap = showScreens.reduce((prev, cur) => {
    // eslint-disable-next-line no-param-reassign
    prev[cur] = true;
    return prev;
  }, {} as Record<Breakpoint, boolean>);

  const filterdColumns = columns.filter(
    column =>
      !column.responsive?.length ||
      column.responsive.some(screen => screenShowMap[screen]),
  );

  const mergedColumns = !expandable
    ? filterdColumns
    : [
        {
          key: 'table-arrow',
          title: '',
          render: (_: any | undefined, item: DataType) => {
            const isActive = curKey === keyExtract(item);
            return (
              <RotatableView
                style={{
                  transform: isActive ? 'rotate(-180deg)' : '',
                  width: 'fit-content',
                }}
              >
                <Icons.Angle direction="down" />
              </RotatableView>
            );
          },
          style: { width: '20px' },
        },
        ...filterdColumns,
      ];

  const columnsRef = useLatest(mergedColumns);
  useEffect(() => {
    const curColumns = columnsRef.current;
    if (sorterKeys.length) {
      setSorterKeys([]);
      setSortDirs([]);
    }
    for (let i = 0, len = curColumns.length; i < len; i++) {
      const order = curColumns[i].defaultSortOrder;
      const { sorter } = curColumns[i];
      if (sorter && order && order !== 'default') {
        if (isMultiple(sorter)) {
          setSorterKeys(keys => [...keys, i]);
          setSortDirs(orders => [...orders, order]);
        } else {
          setSorterKeys([i]);
          setSortDirs([order]);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedColumns.length]);

  const handleSortClick = (key: number) => {
    const len = sorterKeys.length;
    if (!sorterKeys.length) {
      setSorterKeys([key]);
      setSortDirs(['ascend']);
      return;
    }
    const index = sorterKeys.indexOf(key);
    if (index === -1) {
      if (
        isMultiple(mergedColumns[sorterKeys[0]]?.sorter) &&
        isMultiple(mergedColumns[key]?.sorter)
      ) {
        setSorterKeys(keys => [...keys, key]);
        setSortDirs(orders => [...orders, 'ascend']);
      } else {
        setSorterKeys([key]);
        setSortDirs(['ascend']);
      }
    } else if (index !== -1 && sortDirs[index] !== 'descend') {
      const newOrder: SortOrder =
        sortDirs[index] === 'ascend' ? 'descend' : 'ascend';
      setSortDirs(orders => {
        const res = [...orders];
        res.splice(index, 1);
        res.push(newOrder);
        return res;
      });
      setSorterKeys(keys => {
        const res = [...keys];
        [res[index], res[len - 1]] = [res[len - 1], res[index]];
        return res;
      });
    } else {
      setSorterKeys(keys => {
        const res = [...keys];
        res.splice(index, 1);
        return res;
      });
      setSortDirs(orders => {
        const res = [...orders];
        res.splice(index, 1);
        return res;
      });
    }
  };
  const getSortedData = () => {
    const res = [...data];
    // sort by priority
    if (sorterKeys.length > 1) {
      let sortersWithDirs = sorterKeys
        .filter(key => mergedColumns[key]?.sorter)
        .map(
          (key, index) =>
            [mergedColumns[key].sorter, sortDirs[index]] as const,
        ) as [MultipleSorter<DataType>, SortOrder][];
      sortersWithDirs = sortersWithDirs
        .reverse()
        .sort((a, b) => a[0].multiple - b[0].multiple);
      for (const [sorter, order] of sortersWithDirs) {
        const sortFn = sorter.compare;
        if (sortFn) {
          res.sort((a, b) =>
            order === 'descend' ? -sortFn(a, b) : sortFn(a, b),
          );
        }
      }
    } else {
      const sorter = mergedColumns[sorterKeys[0]]?.sorter;
      const sortFn = isFunction(sorter) ? sorter : sorter?.compare;
      if (sortFn) {
        res.sort((a, b) =>
          sortDirs[0] === 'descend' ? -sortFn(a, b) : sortFn(a, b),
        );
      }
    }

    return res;
  };
  const handleRowClick = (item: DataType, index: number) => {
    onRowClick?.(item, index);
    if (expandable) {
      const newKey = keyExtract(item);
      setCurKey(prev => (prev === newKey ? undefined : newKey));
    }
  };
  useEffect(() => {
    expandedRowKey && setCurKey(expandedRowKey);
  }, [expandedRowKey]);
  return (
    <TableContainer
      style={{ width: fullWidth ? '100%' : 'auto', ...containerStyle }}
    >
      <table
        className={cs(`${STYLE_PREFIX}-table`, className)}
        style={style}
      >
        <Header
          columns={mergedColumns}
          onClick={handleSortClick}
          sorterKeys={sorterKeys}
          sortDirs={sortDirs}
          style={headerStyle}
          ascendIcon={ascendIcon}
          desendIcon={desendIcon}
        />

        <tbody style={bodyStyle}>
          {getSortedData().map((item, index) => {
            const isActive = curKey === keyExtract(item);
            return (
              <Fragment key={keyExtract(item) ?? `${index}`}>
                <Row
                  key={keyExtract(item)}
                  item={item as Record<string, unknown>}
                  columns={mergedColumns}
                  onClick={() => handleRowClick(item, index)}
                  style={rowStyle}
                />
                {isActive && (
                  <CollapsedRow>
                    <td
                      colSpan={mergedColumns.length}
                      style={{ padding: 0 }}
                    >
                      {expandedRowRender?.(item, index)}
                    </td>
                  </CollapsedRow>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && renderEmpty}
    </TableContainer>
  );
}

const Row: React.FC<{
  item: Record<string, unknown>;
  columns: Pick<
    TableColumnConfig<any>,
    'render' | 'field' | 'align' | 'key' | 'style' | 'responsive'
  >[];
  onClick: (() => void) | undefined;
  style?: CSSProperties;
}> = ({ columns, item, onClick, style }) => (
  <tr
    className={cs(
      `${STYLE_PREFIX}-table-row`,
      onClick && `${STYLE_PREFIX}-table-row-hover`,
    )}
    onClick={onClick}
    style={style}
  >
    {columns.map(({ field, render, align, key, style }, index) => {
      const value = get(item, field as string);
      const colKey = key ?? `${index}`;

      if (render) {
        return (
          <td key={colKey} style={{ ...style, textAlign: align }}>
            {render(value, item, index)}
          </td>
        );
      }

      return (
        <td key={colKey} style={{ ...style, textAlign: align }}>
          {value ? String(value) : '-'}
        </td>
      );
    })}
  </tr>
);

const CollapsedRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <tr className={`${STYLE_PREFIX}-table-row-expanded`}>{children}</tr>
  );
};

const Header: React.FC<{
  columns: Pick<
    TableColumnConfig<any>,
    'title' | 'align' | 'key' | 'style' | 'sorter' | 'responsive'
  >[];
  onClick: (index: number) => void;
  sorterKeys: number[];
  sortDirs: SortOrder[];
  style?: React.CSSProperties;
  ascendIcon?: ReactNode;
  desendIcon?: ReactNode;
}> = ({
  columns,
  onClick,
  sorterKeys,
  sortDirs,
  style,
  ascendIcon,
  desendIcon,
}) => {
  return (
    <thead style={{ display: 'table-header-group', ...(style ?? {}) }}>
      <tr>
        {columns.map(({ title, align, key, style, sorter }, index) => {
          const position = sorterKeys.indexOf(index);
          const isActive = position !== -1;
          const order = sortDirs[position];
          return (
            <th
              key={key ?? `${index}`}
              style={{
                ...style,
                textAlign: align,
                fontWeight: 'bold',
                cursor: sorter ? 'pointer' : 'auto',
              }}
              onClick={sorter ? () => onClick(index) : undefined}
            >
              <CenterRow style={{ justifyContent: align }}>
                <Txt
                  mr={6}
                  c2
                  bold
                  className={cs({
                    [`${STYLE_PREFIX}-thead-active`]: isActive,
                  })}
                >
                  {title}
                </Txt>
                {title && sorter && (
                  <Col
                    style={{
                      justifyContent: 'center',
                      fontSize: '0.5rem',
                    }}
                    className={cs({
                      [`${STYLE_PREFIX}-thead-hidden`]: !isActive,
                    })}
                  >
                    <CenterRow
                      className={cs({
                        [`${STYLE_PREFIX}-thead-active`]:
                          isActive && order === 'ascend',
                      })}
                    >
                      {ascendIcon ?? <Icons.Angle direction="up" />}
                    </CenterRow>
                    <CenterRow
                      className={cs({
                        [`${STYLE_PREFIX}-thead-active`]:
                          isActive && order === 'descend',
                      })}
                    >
                      {desendIcon ?? <Icons.Angle direction="down" />}
                    </CenterRow>
                  </Col>
                )}
              </CenterRow>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const isMultiple = (
  sorter?: SortFn<any> | { multiple?: number; compare?: SortFn<any> },
): boolean => {
  return !!sorter && !isFunction(sorter) && isNumber(sorter.multiple);
};
