import Big from 'big.js';
import { isUndefined } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getNumberTypeHelper, type NumberType } from './number-utils';

const intermediateInput = /^-?\d+\.0*$/;

const numberStringToFixed = (fullNumStr: string, precision: number) => {
  const numSplitRe = RegExp(
    String.raw`^(-?\d+)((\.\d{1,${precision}})\d*)?$`,
  );
  const match = fullNumStr.match(numSplitRe);
  if (!match) {
    return '';
    // throw Error(`Cannot round ${fullNumStr} into ${precision} digits`);
  }
  const intPart = match[1];
  const fracPart = match[3] ?? '';
  return intPart + fracPart;
};

export { type NumberType } from './number-utils';

export type ParseNumerProps<T extends NumberType> = {
  value: T;
  onChange: (input: number | Big) => void;
  min?: T | string;
  max?: T | string;
  stringify?: (num: T) => string;
  precision?: number;
} & { valueType: 'number' | 'big' };

export function useParseNumberInput<VType extends 'number' | 'big'>({
  value,
  onChange: handleChange,
  min: propMin,
  max: propMax,
  stringify,
  precision = 6,
  valueType,
}: VType extends 'number'
  ? ParseNumerProps<number>
  : ParseNumerProps<Big>) {
  const helper = useMemo(
    () => getNumberTypeHelper(valueType),
    [valueType],
  );

  if (stringify) {
    helper.encode = stringify;
  }

  // temprarily store tolerable intermediate value, like "0."
  const [tolerableValue, setTolerableValue] = useState<
    string | undefined
  >();
  const [error, setError] = useState<Error | undefined>();
  const min =
    typeof propMin === 'string' ? helper.decode(propMin) : propMin;
  const max =
    typeof propMax === 'string' ? helper.decode(propMax) : propMax;

  const onStrChange = (str: string) => {
    const newStr = str === '' ? '0' : str;

    if (intermediateInput.test(newStr)) {
      setTolerableValue(newStr);
      return;
    }
    try {
      const decoded = helper.decode(newStr);
      if (Number.isNaN(decoded)) return;

      if (min && helper.lt(decoded, min)) {
        handleChange(min);
      } else if (max && helper.gt(decoded, max)) {
        handleChange(max);
      } else {
        handleChange(decoded);
      }
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot parse input number ${newStr}: ${e}`);
      setError(e instanceof Error ? e : Error(String(e)));
    }
  };

  useEffect(() => {
    if (tolerableValue) setTolerableValue(undefined);
    if (error) setError(undefined);
    // the `value` can be changed from the outside, i.e., by the slider
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, value]);

  const isEmpty = (value as any).toString() === '-1';

  return {
    value:
      isUndefined(value) || isEmpty
        ? ''
        : tolerableValue ??
          numberStringToFixed(helper.encode(value), precision),
    onChange: onStrChange,
    error,
  };
}
