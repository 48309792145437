import Big, { BigSource } from 'big.js';
import { default as EventEmiiter } from 'eventemitter3';
import { get, isArray, isNil, throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export { default as Big } from 'big.js';
export { default as EventEmiiter } from 'eventemitter3';
export { formatQuoteValue, formatQuoteValueStandard } from './quote-value';

export const isBig = (n: any): n is Big =>
  !isNil(get(n, 's')) && !isNil(get(n, 'e')) && isArray(get(n, 'c'));

export const bigMax = (a: Big, b: Big) => (a.gt(b) ? a : b);
export const bigMin = (a: Big, b: Big) => (a.lt(b) ? a : b);

export const delay = (time: number) =>
  new Promise(r => {
    setTimeout(r, time);
  });

export const useLocalStorageState = (
  key: string,
): [string | undefined, (newState: string) => void] => {
  const [state, setState] = useState<string>(() => {
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return undefined;
  });

  const setLocalStorageState = useCallback(
    (newState: string) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [state, key],
  );

  return [state, setLocalStorageState];
};

export const useWindowWidth = () => {
  const [clientWidth, setClientWidth] = useState<number>(
    window.document.body.clientWidth,
  );

  const setWidth = () => {
    setClientWidth(window.document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  return clientWidth;
};

export const createHumanizeUtil =
  (
    patterns: {
      regexp: RegExp;
      replace: (
        rawErrMsg: string,
        ...params: string[]
      ) => string | React.ReactNode;
    }[],
  ) =>
  (message: string): string | React.ReactNode => {
    for (const pattern of patterns) {
      const res = message.match(pattern.regexp);

      if (!res) continue;

      const replacedNodes: React.ReactNode[] = [];
      const [match, ...params] = Array.from(res);

      const [start, end] = message.split(match);
      const customReplace = pattern.replace(match, ...params);

      replacedNodes.push(start, customReplace, end);

      return replacedNodes;
    }

    return message;
  };

// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.slice(0, chars)}...${address.slice(-chars + 2)}`;
}

export const eventEmitter = new EventEmiiter();

export const tuple = <T, U>(t: T, u: U) => [t, u] as [T, U];

export const useStableCallback = <T extends (...params: any) => any>(
  fn: T,
) => {
  const stableFnRef = useRef(fn);
  stableFnRef.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((...params): ReturnType<T> => {
      return stableFnRef.current(...params);
    }) as T,
    [],
  );
};

export function useDeferredState<T>(
  initState: T,
  minUpdateMillis: number,
) {
  type Upd = (oldState: T) => T;
  const [state, setStateInner] = useState<T>(initState);
  const pendingUpdates = useRef<Array<Upd>>([]);

  const requestFlushUpdates = useStableCallback(
    throttle(() => {
      setStateInner(oldState =>
        pendingUpdates.current.reduce((acc, upd) => upd(acc), oldState),
      );
      pendingUpdates.current = [];
    }, minUpdateMillis),
  );

  const pushUpdate = useStableCallback((updateFn: Upd) => {
    pendingUpdates.current.push(updateFn);
    requestFlushUpdates();
  });

  return tuple(state, pushUpdate);
}

export const arrayFromNums = (len: number) =>
  Array.from({ length: len }, (v, k) => k);

export const isWalletAddress = (str: string) =>
  /^0x[A-Fa-f0-9]{64}$/.test(str);

export const getNowTimestampSec = () =>
  Math.floor(new Date().getTime() / 1000);

export const tryGetNumStrWithK = (num: BigSource) =>
  Big(num).gte(1000)
    ? `${Big(num).div(1000).toNumber().toFixed(1)}k`
    : num.toString();
