import { globalToken } from '@dao/ui-theming';
import styled from 'styled-components';

const {
  borderRadiusLG,
  marginXXS,
  marginSM,
  marginXS,
  colorPrimary,
  colorTextTertiary,
  colorInfoBorder,
  colorWarningBorder,
  colorSuccessBorder,
  colorErrorBorder,
} = globalToken;

const { colorNotificationBg, sizeHeadingIcon } = globalToken.Notification;
export const StyledNotification = styled.div<{
  notifyProgressPct: number;
}>`
  .aries-notification {
    overflow: hidden;
    padding: ${globalToken.Notification.paddingNotification};
    background-color: ${colorNotificationBg};
    border-radius: ${borderRadiusLG}px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    flex: 1;
    text-align: left;
    margin: ${marginXXS}px 0px;

    .icon.notification-heading {
      margin-right: ${marginSM}px;
    }

    .notification-body {
      min-width: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      .title {
        font-weight: 500;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;

        .title-content {
          display: flex;
          flex: 1;
        }
      }

      .notification-close {
        margin-right: 0px;
        height: 18px;
        line-height: 18px;
        margin-top: -1px;
        border-radius: ${globalToken.Notification
          .borderRadiusNotificationClose}px;
        padding: ${globalToken.Notification.paddingNotificationClose}px;
        color: ${globalToken.Notification.colorNotificationClose};
        :hover {
          background-color: ${globalToken.Notification
            .colorNotificationCloseBgHover};
        }
      }

      .content {
        word-break: break-all;
        font-weight: 400;
        color: ${globalToken.Notification
          .colorNotificationContent} !important;
      }

      .time {
        font-size: 0.8em;
        margin-top: ${marginXS}px;
        opacity: 0.75;
      }

      .manage {
        margin-top: ${marginXS}px;
        font-weight: 500;
        color: ${colorPrimary};
        cursor: pointer;
      }
    }

    a {
      font-weight: 500;

      &:any-link {
        color: inherit;
      }

      cursor: pointer;
    }

    .heading-icon {
      font-size: ${sizeHeadingIcon} !important;
    }

    .progress-bar {
      display: ${globalToken.Notification.showProgressBar
        ? 'block'
        : 'none'};
      position: absolute;
      bottom: 0;
      left: 2px;
      height: 2px;
      width: ${p => p.notifyProgressPct}%;
      transition: width 400ms ease-in-out;
    }
  }

  .aries-notification-info {
    border: 1px solid ${colorInfoBorder};

    .heading-icon svg path {
      color: ${globalToken.colorPrimary};
    }

    .progress-bar {
      background-color: ${globalToken.colorPrimary};
    }
    .icon {
      color: ${colorTextTertiary};
    }

    .title {
      color: ${globalToken.Notification.colorNotificationInfoTitle};
    }
  }

  .aries-notification-warning {
    border: 1px solid ${colorWarningBorder};

    .heading-icon svg path {
      color: ${globalToken.colorWarning};
    }

    .progress-bar {
      background-color: ${globalToken.colorWarning};
    }
    .icon {
      color: ${colorTextTertiary};
    }

    .title {
      color: ${globalToken.Notification.colorNotificationWarningTitle};
    }
  }

  .aries-notification-success {
    border: 1px solid ${colorSuccessBorder};

    .heading-icon svg path {
      color: ${globalToken.colorSuccess};
    }

    .progress-bar {
      background-color: ${globalToken.colorSuccess};
    }
    .icon {
      color: ${colorTextTertiary};
    }

    .title {
      color: ${globalToken.Notification.colorNotificationSuccessTitle};
    }
  }

  .aries-notification-error {
    border: 1px solid ${colorErrorBorder};

    .heading-icon svg path {
      color: ${globalToken.colorError};
    }

    .progress-bar {
      background-color: ${globalToken.colorError};
    }
    .icon {
      color: ${colorTextTertiary};
    }

    .title {
      color: ${globalToken.Notification.colorNotificationErrorTitle};
    }
  }
`;
