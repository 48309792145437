import { ColorToken, globalToken } from '@dao/ui-theming';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { designColor } from '../common';
import { media } from '../responsive';

const { transitionSlow } = globalToken;
export type ViewProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;
  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;
  flex?: number;
  gap?: number;
  w?: number | string;
  h?: number | string;
  fullWidth?: boolean;
  clickable?: boolean;
  relative?: boolean;
  absolute?: boolean;
  fixed?: boolean;
  bgColor?: keyof ColorToken;
  radius?: number;
};

export const AnimatedView = styled(motion.div)<ViewProps>`
  margin: ${p => (p.mg ? `${p.mg}px` : undefined)};
  margin-left: ${p => (p.ml ? `${p.ml}px` : undefined)};
  margin-right: ${p => (p.mr ? `${p.mr}px` : undefined)};
  margin-top: ${p => (p.mt ? `${p.mt}px` : undefined)};
  margin-bottom: ${p => (p.mb ? `${p.mb}px` : undefined)};

  padding: ${p => (p.pd ? `${p.pd}px` : undefined)};
  padding-left: ${p => (p.pl ? `${p.pl}px` : undefined)};
  padding-right: ${p => (p.pr ? `${p.pr}px` : undefined)};
  padding-top: ${p => (p.pt ? `${p.pt}px` : undefined)};
  padding-bottom: ${p => (p.pb ? `${p.pb}px` : undefined)};

  height: ${props => props.h ?? undefined};
  width: ${props => (props.fullWidth ? '100%' : props.w ?? undefined)};
  flex: ${props => props.flex ?? undefined};
  gap: ${props => (props.gap ? `${props.gap}px` : undefined)};
  cursor: ${props => (props.clickable ? 'pointer' : undefined)};
  background-color: ${p => designColor(p.bgColor) ?? undefined};
  border-radius: ${p => (p.radius ? `${p.radius}px` : undefined)};
`;

export const View = styled.div<ViewProps>`
  margin: ${p => (p.mg ? `${p.mg}px` : undefined)};
  margin-left: ${p => (p.ml ? `${p.ml}px` : undefined)};
  margin-right: ${p => (p.mr ? `${p.mr}px` : undefined)};
  margin-top: ${p => (p.mt ? `${p.mt}px` : undefined)};
  margin-bottom: ${p => (p.mb ? `${p.mb}px` : undefined)};

  padding: ${p => (p.pd ? `${p.pd}px` : undefined)};
  padding-left: ${p => (p.pl ? `${p.pl}px` : undefined)};
  padding-right: ${p => (p.pr ? `${p.pr}px` : undefined)};
  padding-top: ${p => (p.pt ? `${p.pt}px` : undefined)};
  padding-bottom: ${p => (p.pb ? `${p.pb}px` : undefined)};

  height: ${props => props.h ?? undefined};
  width: ${props => (props.fullWidth ? '100%' : props.w ?? undefined)};
  flex: ${props => props.flex ?? undefined};
  gap: ${props => (props.gap ? `${props.gap}px` : undefined)};
  cursor: ${props => (props.clickable ? 'pointer' : undefined)};
  background-color: ${p => designColor(p.bgColor) ?? undefined};
  border-radius: ${p => (p.radius ? `${p.radius}px` : undefined)};
  position: ${props =>
    props.relative
      ? 'relative'
      : props.absolute
      ? 'absolute'
      : props.fixed
      ? 'fixed'
      : ''};
`;

export const RotatableView = styled(View)`
  transition: transform ${transitionSlow};
`;

export const Row = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const Col = styled(View)`
  display: flex;
  flex-direction: column;
`;

export const EndCol = styled(View)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const BetweenCol = styled(View)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CenterCol = styled(View)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CenterRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ResponsiveRow = styled(View)`
  display: flex;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
    align-items: center;
  `}
`;

export const CenterBetweenRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BetweenRow = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EndRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const EndStartRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const EndBetweenRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
