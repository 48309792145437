import { globalToken } from '@dao/ui-theming';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';
import {
  AlignInputOpt,
  InputBox,
  InputLabel,
  InputProps,
  InputWrapper,
} from './common';

const { colorTextTertiary } = globalToken;
const PureTextArea = styled.textarea<{
  align?: AlignInputOpt;
  disabled?: boolean;
}>`
  border: none;
  background: transparent;
  outline: none;
  text-align: ${p => p.align};
  flex: ${p => (p.align === 'center' ? undefined : 1)};
  ::-webkit-input-placeholder {
    color: ${colorTextTertiary};
  }

  ::placeholder {
    color: ${colorTextTertiary};
  }

  &:hover {
    cursor: ${p => (p.disabled ? 'not-allowed' : 'text')};
  }
`;

export function TextArea({
  value,
  label,
  onChange,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  error,
  disabled = false,
  alignInput = 'left',
  inline = false,
  borderless = false,
  inputStyle,
  wrapperStyle,
  className,
  placeholder,
  autoFocus,
  rows,
  cols,
  maxLength,
}: Omit<InputProps, 'suffix' | 'prefix'> & {
  rows?: number;
  cols?: number;
  maxLength?: number;
}) {
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputWrapper
      inline={inline}
      className={cx(
        `${STYLE_PREFIX}-input-root`,
        disabled ? `${STYLE_PREFIX}-input-root-disabled` : null,
        className,
      )}
      disabled={disabled}
    >
      {label && <InputLabel focused={focused}>{label}</InputLabel>}
      <InputBox
        onClick={() => !disabled && inputRef.current?.focus()}
        className={cx(`${STYLE_PREFIX}-input-box`, {
          error,
          disabled,
          focused,
        })}
        {...{
          disabled,
          focused,
          alignInput,
          inline,
          borderless,
        }}
        error={!!error}
        style={wrapperStyle}
      >
        <PureTextArea
          value={value}
          onChange={e => {
            !disabled && onChange?.(e.target.value);
          }}
          placeholder={placeholder}
          className={`${STYLE_PREFIX}-input-inner`}
          ref={inputRef}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            onFocus?.();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur?.();
          }}
          align={alignInput}
          style={inputStyle}
          {...{ onMouseEnter, onMouseLeave }}
          rows={rows}
          cols={cols}
          maxLength={maxLength}
        />
      </InputBox>
    </InputWrapper>
  );
}
