import { globalToken } from '@dao/ui-theming';
import { isNumber, isString, noop } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { Icons } from '../icon';
import { BetweenRow, CenterRow, RotatableView, View } from '../layout';
import { Txt } from '../txt';

const prefixCls = `${STYLE_PREFIX}-collapse`;
const { paddingContentVertical, marginSM, marginXS, transitionSlow } =
  globalToken;

export interface CollapsePanelProps {
  desc?: React.ReactNode;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  header?: React.ReactNode;
  isActive?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export const CollapsePanel = ({
  desc,
  style,
  headerStyle,
  contentStyle,
  header,
  children,
  isActive = false,
  onClick = noop,
}: CollapsePanelProps) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const collapsedElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setInterval(() => {
      // update maxHeight in real time in case there're nested collapse components
      const scrollHeight = collapsedElement.current?.scrollHeight;
      if (isNumber(scrollHeight)) {
        setMaxHeight(prev => (scrollHeight > prev ? scrollHeight : prev));
      }
    }, 500);
  }, []);

  return (
    <div className={`${prefixCls}-item`} style={style}>
      <BetweenRow
        onClick={onClick}
        pb={paddingContentVertical}
        pt={paddingContentVertical}
        style={{ cursor: 'pointer', ...headerStyle }}
      >
        {isString(header) ? (
          <Txt h6 bold>
            {header}
          </Txt>
        ) : (
          header
        )}
        <CenterRow>
          <Txt h3 mr={marginSM}>
            {desc}
          </Txt>
          <RotatableView
            style={{ transform: isActive ? 'rotate(-180deg)' : '' }}
          >
            <Icons.Angle direction="down" />
          </RotatableView>
        </CenterRow>
      </BetweenRow>

      <CollapsibleView
        ref={el => {
          el && setMaxHeight(el.scrollHeight);
          collapsedElement.current = el;
        }}
        className={`${STYLE_PREFIX}-content`}
        maxHeight={(isActive && maxHeight) || 0}
        mb={marginXS}
        style={contentStyle}
      >
        {children}
      </CollapsibleView>
    </div>
  );
};

const CollapsibleView = styled(View)<{ maxHeight: number }>`
  width: 100%;
  max-height: ${props => `${props.maxHeight}px`};
  transition: max-height ${transitionSlow};
  overflow-y: hidden;
`;
