import cx from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { NotificationBar } from '../bar';
import { NotificationType } from '../base';
import { Notification, NotificationLevel } from './NotificationManager';
import { NotificationsContainer } from './style';

import {
  createNotificationApi,
  useNotificationContents,
} from './useNotification';

dayjs.extend(relativeTime);

export { NotificationLevel } from './NotificationManager';
export const Notify = createNotificationApi();

export function NotificationProvider({
  children = null,
}: {
  children: React.ReactNode;
}) {
  const notifications = useNotificationContents();

  return (
    <>
      {children}
      <NotificationsContainer>
        <div id="notification-runtime">
          <div className="notification-container">
            {notifications.map((n, index) => (
              <NotificationToast
                key={`notification-${index.toString()}`}
                notification={n}
              />
            ))}
          </div>
        </div>
      </NotificationsContainer>
    </>
  );
}

/** @deprecated bridge notification level to new notification type */
const mapNotificationLevelToType: Record<
  NotificationLevel,
  NotificationType
> = {
  [NotificationLevel.SUCCESS]: 'success',
  [NotificationLevel.INFO]: 'info',
  [NotificationLevel.WARN]: 'warning',
  [NotificationLevel.ERROR]: 'error',
  [NotificationLevel.UNKNOWN]: 'info',
};

function NotificationToast({
  notification: n,
  forceShow,
}: {
  notification: Notification;
  forceShow?: boolean;
}) {
  const body = (
    <>
      {n.params.description}
      {n.params.url && (
        <div className={cx('toast-link', 'font-monospace')}>
          <a href={n.params.url} rel="noreferrer noopener" target="_blank">
            {n.params.url}
          </a>
        </div>
      )}
    </>
  );

  return (
    <NotificationBar
      type={mapNotificationLevelToType[n.level]}
      title={n.params.message}
      content={body}
      closeable={!forceShow}
      timestamp={dayjs(n.timestamp).fromNow()}
      onClose={n.handle.close}
      display={forceShow || n.visible}
    />
  );
}
