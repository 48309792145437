import { useCallback, useState } from 'react';

export const delay = (time: number) =>
  new Promise(r => {
    setTimeout(r, time);
  });

export const useLocalStorageState = (
  key: string,
): [string | undefined, (newState: string) => void] => {
  const [state, setState] = useState<string>(() => {
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return undefined;
  });

  const setLocalStorageState = useCallback(
    (newState: string) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [state, key],
  );

  return [state, setLocalStorageState];
};
