import { globalToken } from '@dao/ui-theming';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';

const { colorTextBase, colorText, colorTextTertiary } = globalToken;

const {
  paddingHeadCellVertical,
  paddingHeaderCellHorizontal,
  paddingDataCellVertial,
  paddingDataCellHorizontal,
  paddingRowVertical,
  paddingRowHorizontal,
  borderRadiusTable,
  borderRadiusTableRow,
  borderWidthTheadBottom,
  borderWidthTheadTop,
  borderWidthTheadRight,
  borderWidthTheadLeft,
  colorTableBg,
  colorTheadBg,
  colorTableBgHover,
  colorTableBorder,
  colorTheadBorder,
} = globalToken.Table;

export const TableContainer = styled.div`
  color: ${colorTextBase};

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  th {
    padding: ${paddingHeadCellVertical}px ${paddingHeaderCellHorizontal}px;
    border-top: ${borderWidthTheadTop}px solid ${colorTheadBorder};
    border-bottom: ${borderWidthTheadBottom}px solid ${colorTheadBorder};

    &:first-child {
      border-left: ${borderWidthTheadLeft}px solid ${colorTheadBorder};
    }

    &:last-child {
      border-right: ${borderWidthTheadRight}px solid ${colorTheadBorder};
    }
  }

  td {
    padding: ${paddingDataCellVertial}px ${paddingDataCellHorizontal}px;
    border-bottom: 1px solid ${colorTableBorder};
    font-weight: 400;
  }

  td > * {
    font-size: 1rem;
    font-weight: 400;
  }

  thead {
    color: ${colorTextTertiary};
    font-size: 12px;
    line-height: 18px;

    .${STYLE_PREFIX}-thead {
      &-active,
      &-active * {
        color: ${colorText} !important;
      }

      &-hidden {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }

    tr {
      background: ${colorTheadBg};
    }
  }

  .gap {
    height: 8px;
    width: 100%;
  }

  .${STYLE_PREFIX}-table-row {
    background-color: ${colorTableBg};
    padding: ${paddingRowVertical}px ${paddingRowHorizontal}px;

    td:first-child {
      border-left: 1px solid ${colorTableBorder};
    }
    td:last-child {
      border-right: 1px solid ${colorTableBorder};
    }

    td > * {
      font-size: 1rem;
      font-weight: 400;
    }

    &:hover {
      background-color: ${colorTableBgHover};
    }

    &-expanded .${STYLE_PREFIX}-table {
      border-collapse: collapse;
    }
  }

  .${STYLE_PREFIX}-table-row-hover {
    &:hover {
      cursor: pointer;
    }
  }

  table tr:first-child th:first-child {
    border-top-left-radius: ${borderRadiusTable}px;
  }

  table tr:first-child th:last-child {
    border-top-right-radius: ${borderRadiusTable}px;
  }
  table tr:last-child td:first-child {
    border-bottom-left-radius: ${borderRadiusTable}px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: ${borderRadiusTable}px;
  }

  td:first-child {
    border-top-left-radius: ${borderRadiusTableRow}px;
    border-bottom-left-radius: ${borderRadiusTableRow}px;
  }

  td:last-child {
    border-top-right-radius: ${borderRadiusTableRow}px;
    border-bottom-right-radius: ${borderRadiusTableRow}px;
  }
`;
