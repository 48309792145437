import React, { useEffect, useState } from 'react';
// @ts-ignore
import Impl from 'react-animated-number';

export const AnimatedNumber: React.FC<{
  value: number;
  initialValue?: number;
  fontStyle?: React.CSSProperties;
  formatValue?: (num: number) => string;
  duration?: number;
  stepPrecision?: number;
}> = ({
  initialValue = 0,
  fontStyle,
  duration,
  stepPrecision = 2,
  value,
  formatValue,
}) => {
  const [animatedValue, setValue] = useState(initialValue);

  useEffect(() => {
    requestAnimationFrame(() => {
      setValue(value);
    });
  }, [value]);

  return (
    <Impl
      component="span"
      value={animatedValue}
      style={{
        ...fontStyle,
      }}
      duration={duration ?? 350}
      stepPrecision={stepPrecision}
      formatValue={(n: number) => formatValue?.(n) ?? n}
    />
  );
};
