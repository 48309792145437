import { colorConfig, useThemeMode } from '@dao/ui-theming';
import { BetweenRow, View } from '../layout';
import { Txt } from '../txt';
import { Slider, SliderProps } from './base';

const { colorIconSuccess, colorIconWarning, colorIconCritical } =
  colorConfig;

type RiskSliderProps = SliderProps & {
  base?: number; // risk lower bound when 'asc', upper bound when 'dsc'
  direction?: 'asc' | 'dsc';
};

const RISK_TICKS = ['Low Risk', 'Medium Risk', 'High Risk'];

export function RiskSlider({
  value: currentRisk,
  onChange,
  direction = 'asc',
  base = direction === 'asc' ? 0 : 1,
}: RiskSliderProps) {
  const { mode } = useThemeMode();
  const ticks = direction === 'asc' ? RISK_TICKS : RISK_TICKS.reverse();
  const rangeLimit = direction === 'asc' ? 1 : 0;

  /**
   * linear-gradient background is hard to mask or crop.
   * it is a workaround to stretch the gradient to map to the right colors in the given percentage.
   */
  const mapRiskToGradientPct = (r: number) =>
    `${(((r - base) / (currentRisk - base)) * 100).toFixed(2)}%`;
  const mapRiskToSliderPct = (r: number) =>
    ((r - base) / (rangeLimit - base)) * 100;

  const background = `linear-gradient(
    90deg,
    ${colorIconSuccess[mode]} ${mapRiskToGradientPct(0)},
    ${colorIconWarning[mode]} ${mapRiskToGradientPct(0.5)},
    ${colorIconCritical[mode]} ${mapRiskToGradientPct(1)}
   )`;

  return (
    <View fullWidth>
      <Slider
        value={mapRiskToSliderPct(currentRisk) || 0}
        onChange={onChange}
        background={background}
      />
      <BetweenRow>
        {ticks.map(tick => (
          <Txt h1 c2 key={tick}>
            {tick}
          </Txt>
        ))}
      </BetweenRow>
    </View>
  );
}
