import { CommonToken } from '@dao/ui-theming';

export const commonToken: CommonToken = {
  fontFamily: 'Space Grotesk',
  borderRadius: 8,
  borderRadiusXS: 2,
  borderRadiusSM: 4,
  borderRadiusLG: 12,
  borderRadiusXL: 16,
  borderRadiusXXL: 24,
  borderRadiusOuter: 4,
  paddingXXS: 4,
  paddingXS: 8,
  paddingSM: 12,
  padding: 16,
  paddingMD: 20,
  paddingLG: 24,
  paddingXL: 32,
  paddingXXL: 48,
  paddingContentHorizontal: 16,
  paddingContentHorizontalSM: 12,
  paddingContentHorizontalLG: 24,
  paddingContentVertical: 8,
  paddingContentVerticalSM: 8,
  paddingContentVerticalLG: 16,
  marginXXS: 4,
  marginXS: 8,
  marginSM: 12,
  margin: 16,
  marginMD: 20,
  marginLG: 24,
  marginXL: 32,
  marginXXL: 48,
  transition: '200ms ease',
  transitionSlow: '300ms ease',
};
