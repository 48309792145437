import Big, { BigSource } from 'big.js';
import { isNumber } from 'lodash';

export const formatQuoteValue = (value: number) => {
  const displayStr = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumSignificantDigits: 3,
  }).format(value);

  return displayStr;
};

export const formatPercentage = (pctValue: BigSource) => {
  const pct = Big(pctValue);
  if (pct.abs().lt(1000)) {
    return `${pct.toFixed(2)}%`;
  }
  return `${pct.div(100).toFixed(2)}x`;
};

export const formatQuoteValueStandard = (value: Big | number) => {
  const displayStr = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'standard',
  }).format(isNumber(value) ? value : value.toNumber());

  return displayStr;
};
