import { globalToken, ThemeSet } from '@dao/ui-theming';

const {
  colorTextCritical,
  colorTextSuccess,
  colorTextWarning,
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorTextQuaternary,
} = globalToken;

type TxtSize =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 'h9'
  | 'h10'
  | 'h11'
  | 'h12'
  | 'h13';

type TxtColor =
  | 'c1'
  | 'c2'
  | 'c3'
  | 'c4'
  | 'info'
  | 'success'
  | 'warning'
  | 'critical'
  | 'yellow'
  | 'green'
  | 'white'
  | 'orange'
  | 'red'
  | 'gray'
  | 'blue';

type TxtSizeProps = { [key in TxtSize]?: boolean };
type TxtColorProps = { [key in TxtColor]?: boolean };
export type TxtProps = TxtSizeProps &
  TxtColorProps & {
    bigger?: number | boolean; // resize based on em, TODO
    smaller?: number | boolean; // resize based on em, TODO
    inheritSize?: boolean;
    onPress?: () => void;
  };

export const getSizeProps = (
  props: TxtSizeProps,
): { fontSize: string; lineHeight: string } => {
  const propNames = Object.keys(props) as TxtSize[];
  for (const propName of propNames) {
    if (propName.match(/inheritSize/)) {
      return {
        fontSize: 'inherit',
        lineHeight: 'inherit',
      };
    }

    const size = Number(propName.match(/^h(\d{1,2})$/)?.[1]);
    if (size > 0 && size < 14 && props[propName]) {
      return {
        fontSize: `${10 + 2 * size}px`,
        lineHeight: `${12 + 4 * size}px`,
      };
    }
  }
  return {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  };
};

export const getColor = (props: TxtColorProps): ThemeSet | undefined => {
  const propNames = Object.keys(props) as TxtColor[];
  for (const propName of propNames) {
    if (
      /^(c\d{1,2}|critical|success|warning)$/.test(propName) &&
      props[propName]
    ) {
      return propThemeSetMap[propName];
    }
  }
  return colorText;
};

const propThemeSetMap: { [key in TxtColor]?: ThemeSet } = {
  c1: colorText,
  c2: colorTextSecondary,
  c3: colorTextTertiary,
  c4: colorTextQuaternary,
  critical: colorTextCritical,
  success: colorTextSuccess,
  warning: colorTextWarning,
};
