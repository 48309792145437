import React, { useEffect, useState } from 'react';

export const DelayRender: React.FC<{
  delay?: number;
  fallback?: React.ReactNode;
}> = ({ children, delay, fallback }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    } else {
      requestAnimationFrame(() => {
        setShow(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (show ? children : fallback ?? null) as React.ReactElement;
};

export const getDefaultDataKey = (data: unknown) => String(data);
