import { globalToken } from '@dao/ui-theming';
import { css } from 'styled-components';

const { transition, colorTextTertiary } = globalToken;

const {
  borderRadiusInput,
  paddingInput,
  heightInput,
  boxShadowInputActive,
  colorTextInput,
  colorInputBorder,
  colorInputBg,
  colorInputBorderHover,
  colorInputBgHover,
  colorInputBorderActive,
  colorInputBorderActiveHover,
  colorInputBgActive,
  colorInputBorderDisabled,
  borderWidthInput,
  colorInputBgDisabled,
  colorInputBorderCritial,
} = globalToken.Input;

export const InputBaseStyle = css`
  overflow: hidden;
  color: ${colorTextInput};
  border-radius: ${borderRadiusInput};
`;

export const InputStandardStyle = css`
  ${InputBaseStyle}
  padding: ${paddingInput};
  height: ${heightInput};
  border: ${borderWidthInput}px solid ${colorInputBorder};
  border-radius: ${borderRadiusInput};
  background-color: ${colorInputBg};
  transition: border ${transition};
  &:hover {
    border: ${borderWidthInput}px solid ${colorInputBorderHover};
    background-color: ${colorInputBgHover};
  }
`;

export const InputActivatedStyle = css`
  background-color: ${colorInputBgActive};
  border: ${borderWidthInput}px solid ${colorInputBorderActive};
  background-color: ${colorInputBgActive};
  box-shadow: ${boxShadowInputActive};

  :hover {
    border: ${borderWidthInput}px solid ${colorInputBorderActiveHover};
    background-color: ${colorInputBgActive};
  }
`;

export const InputDisabledStyle = css`
  & * {
    color: ${colorTextTertiary};
  }
  border: ${borderWidthInput}px solid ${colorInputBorderDisabled};
  background-color: ${colorInputBgDisabled};
  &:hover {
    border-color: ${colorInputBorderDisabled};
    background-color: ${colorInputBgDisabled};
    cursor: not-allowed;
  }
`;

export const InputErrorStyle = css`
  border: ${borderWidthInput}px solid ${colorInputBorderCritial};
`;

export const InputBorderlessStyle = css`
  transition: background-color ${transition};
  border: none;
`;
