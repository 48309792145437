import { globalToken } from '@dao/ui-theming';
import MenuImpl from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import styled from 'styled-components';
import { Col } from '../layout';
import { Txt } from '../txt';

export const DropdownMenu: React.FC<{
  items: {
    onClick?: () => void;
    title: React.ReactNode;
    description?: React.ReactNode;
    render?: () => React.ReactNode;
    style?: React.CSSProperties;
  }[];
  onVisibleChange?: (visible: boolean) => void;
  offset?: { left: number; top: number };
  style?: React.CSSProperties;
  disabled?: boolean;
}> = ({ children, items, onVisibleChange, offset, style, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    onVisibleChange?.(false);
  };

  return (
    <>
      <MenuStyle
        onClick={e => {
          if (disabled) return;

          setAnchorEl(e.currentTarget);
          onVisibleChange?.(true);
        }}
      >
        {children}
      </MenuStyle>
      <MenuImpl
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorPosition={offset}
        style={style}
      >
        {items.map(
          (
            { render, title, description, onClick, style: itemStyle },
            index,
          ) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                onClick?.();
              }}
              style={itemStyle}
            >
              {render ? (
                render()
              ) : (
                <Col>
                  <Txt h3>{title}</Txt>
                  {description && <Txt h2>{description}</Txt>}
                </Col>
              )}
            </MenuItem>
          ),
        )}
      </MenuImpl>
    </>
  );
};

const {
  colorBgContainer,
  colorBgSpotlight,
  colorBgElevated,
  borderRadiusSM,
  paddingXS,
} = globalToken;
const MenuStyle = styled.div`
  .MuiList-padding {
    background-color: ${colorBgContainer} !important;
    padding: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiMenuItem-root {
    padding: 5px 12px !important;
    border-radius: ${borderRadiusSM}px !important;
    &:hover {
      background-color: ${colorBgSpotlight} !important;
    }
  }

  .MuiPopover-paper {
    background-color: ${colorBgContainer} !important;
    border: 1px solid ${colorBgElevated} !important;
    padding: ${paddingXS}px !important;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
