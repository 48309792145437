import { globalToken } from '@dao/ui-theming';
import styled from 'styled-components';

export const NotificationsContainer = styled.div`
  #notification-runtime {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 5000000; // need to be higher than modal's background
    width: ${globalToken.Notification.widthNotification};

    @keyframes slidein {
      from {
        max-height: 0;
      }

      to {
        max-height: 200px;
      }
    }

    .toast {
      animation-duration: 0.5s;
      animation-name: slidein;
      overflow-y: hidden;
    }
  }

  .notifi-modal-header {
    width: 100%;
    height: 50px;
  }
  #notification-history-modal {
    .modal-body {
      .notification-container {
        align-items: center;
      }
    }
  }

  .notification-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    overflow: hidden;
    max-height: 100vh;
  }
`;
