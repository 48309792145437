// eslint-disable-next-line prettier/prettier
import './styles';
import { initNotifyUtil } from '@dao/dao-api/utils/notify';
import { withLoadingNotify, withSendTxNotify } from './utils/index';

document
  .getElementsByTagName('html')[0]
  .setAttribute('data-theme', 'dark');

initNotifyUtil(withSendTxNotify, withLoadingNotify as any);
