import { useLatest } from '@dao/shared/hooks';
import { shortenAddress } from '@dao/shared/utils';
import {
  CenterRow,
  Col,
  Notify,
  openModal,
  styled,
  Txt,
  CenterBetweenRow,
  useIsMobile,
} from '@dao/ui-design';
import { globalToken } from '@dao/ui-theming';
import {
  AptosWalletAdapterProvider as WalletProviderImpl,
  useWallet as useWalletInner,
  WalletReadyState,
  NetworkName,
} from '@aptos-labs/wallet-adapter-react';
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';
import { NightlyWallet } from '@nightlylabs/aptos-wallet-adapter-plugin';
import { PontemWallet } from '@pontem/wallet-adapter-plugin';
import { RiseWallet } from '@rise-wallet/wallet-adapter';
import { SpikaWallet } from '@spika/aptos-plugin';
import { TrustWallet } from '@trustwallet/aptos-wallet-adapter';
import { WelldoneWallet } from '@welldone-studio/aptos-wallet-adapter';
import { FewchaWallet } from 'fewcha-plugin-wallet-adapter';
import { MSafeWalletAdapter } from 'msafe-plugin-wallet-adapter';
import { PetraWallet } from 'petra-plugin-wallet-adapter';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BloctoWallet } from '@blocto/aptos-wallet-adapter-plugin';
import { useWallet } from './hook';

const {
  colorSurfaceHover,
  colorFillContentHover,
  padding,
  paddingSM,
  borderRadiusLG,
  colorBorderSecondary,
} = globalToken;
export const WalletProvider: React.FC = ({ children }) => {
  const [wallets, setWallets] = useState<any[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setWallets([
        new PontemWallet(),
        new BloctoWallet({
          network: NetworkName.Mainnet,
          bloctoAppId: '774418fe-0a28-4a49-bd4f-5cc513178fc8',
        }),
        new RiseWallet(),
        new MartianWallet(),
        new MSafeWalletAdapter(),
        new SpikaWallet(),
        new FewchaWallet(),
        new PetraWallet(),
        new TrustWallet(),
        new WelldoneWallet(),
        new NightlyWallet(),
      ]);
    }, 100);
  }, []);

  if (wallets.length === 0) {
    return null;
  }

  return (
    <WalletProviderImpl plugins={wallets} autoConnect>
      <>
        {children}
        <SelectWalletModal />
        <WalletCtxSpy />
      </>
    </WalletProviderImpl>
  );
};

const WalletCtxSpy = () => {
  const { setWalletCtx } = useWallet();
  const adapter = useWalletInner();
  const { connected, account } = adapter;

  useEffect(() => {
    if (connected && adapter && account?.address) {
      const address = account.address.toString();

      const walletCtx = {
        adapter,
        address,
      };

      setWalletCtx(walletCtx);

      Notify.success(
        'Wallet Connected.',
        `Address: ${shortenAddress(address, 6)}`,
      );
    } else {
      setWalletCtx(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, adapter, account?.address]);

  return null;
};

const SelectWalletModal: React.FC<{}> = () => {
  const { walletModalShow, setWalletModalShow } = useWallet();

  const {
    wallet,
    disconnect,
    connect,
    wallets: adapters,
  } = useWalletInner();
  const connectRef = useLatest(connect);
  const disconnectRef = useLatest(disconnect);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (walletModalShow) {
      const readyStateTransMap: Record<WalletReadyState, string> = {
        Installed: 'Installed',
        NotDetected: 'NotDetected',
        Loadable: 'Loadable',
        Unsupported: 'Unsupported',
      };
      const res = openModal({
        // Default: Installed,NotDetected,Loadable,Unsupported
        title: (
          <Txt h6 bold>
            Connect Wallet
          </Txt>
        ),
        styles: {
          inner: {
            padding: isMobile ? '0 !important' : 'auto',
            overflow: 'hidden',
            height: isMobile ? '100%' : '',
          },
          modal: {
            height: isMobile ? '100%' : '',
            borderRadius: isMobile ? '0 !important' : '',
            padding: isMobile ? '32px 24px 32px 32px !important' : '32px',
            overflow: 'hidden',
          },
        },
        children: (
          <Col style={{ overflowX: 'hidden' }} mt={16}>
            <Txt h3 c3 mb={16}>
              Please switch your wallet to CORRECT NETWORK.
            </Txt>
            <CenterBetweenRow
              style={{
                flexWrap: 'wrap',
                width: isMobile ? 'calc(100vw - 56px)' : 400,
              }}
            >
              {sortBy(adapters, a =>
                a.readyState === WalletReadyState.Installed ||
                a.readyState === WalletReadyState.Loadable
                  ? -1
                  : 0,
              ).map(i => (
                <WalletItem
                  disabled={false}
                  key={i.name}
                  isActive={wallet?.name === i.name}
                  onClick={async () => {
                    try {
                      disconnectRef.current();
                      // eslint-disable-next-line no-empty
                    } catch {}
                    setTimeout(async () => {
                      try {
                        if (
                          i.readyState !== WalletReadyState.Installed &&
                          i.readyState !== WalletReadyState.Loadable
                        ) {
                          window.open(i.url);
                        }
                        connectRef.current(i.name);
                        res.close();
                        // eslint-disable-next-line no-empty
                      } catch {}
                    }, 0);
                  }}
                  mt={10}
                >
                  <img
                    alt=""
                    style={{
                      height: isMobile ? 24 : 45,
                      width: isMobile ? 24 : 45,
                    }}
                    src={i.icon}
                  />
                  <Col ml={12}>
                    <Txt h3 bold>
                      {i.name}
                    </Txt>
                    <Txt
                      c2
                      h2
                      color={
                        i.readyState === WalletReadyState.Installed ||
                        i.readyState === WalletReadyState.Loadable
                          ? 'colorTextHighlight'
                          : undefined
                      }
                    >
                      {i.readyState
                        ? readyStateTransMap[i.readyState]
                        : ''}
                    </Txt>
                  </Col>
                </WalletItem>
              ))}
            </CenterBetweenRow>
          </Col>
        ),
      });
      setWalletModalShow(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletModalShow]);
  return null;
};

// @ts-ignore
const WalletItem = styled(CenterRow)<{
  isActive: boolean;
  disabled: boolean;
}>`
  width: 49%;
  padding-left: ${padding}px;
  background-color: ${p =>
    p.disabled
      ? colorSurfaceHover
      : p.isActive
      ? colorFillContentHover
      : undefined};
  cursor: pointer;
  border-radius: ${borderRadiusLG}px;
  border: 2px solid ${colorBorderSecondary};
  padding: 12px 16px;
  :hover {
    background-color: ${colorFillContentHover};
  }
  padding: ${padding}px ${paddingSM}px;
`;
