import { NumberType, useParseNumberInput } from '@dao/shared/hooks';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { CenterRow } from '../layout';
import { Txt } from '../txt';
import { InputProps } from './input';
import { NumeralInput } from './numeral-input';

export type ScaleType = 'ms' | 's' | 'm' | 'h' | 'd';

const scaleNameMap: Record<ScaleType, string> = {
  d: 'Day',
  h: 'Hour',
  m: 'Min',
  s: 'Sec',
  ms: 'Ms',
};
export type TimeDurationInputProps<T extends NumberType> = {
  value: T | undefined;
  onChange: (input: T) => void;
  min?: T;
  max?: T;
  scale: ScaleType;
  valueType: 'number' | 'big';
  autoFormat?: boolean;
} & Omit<InputProps, 'value' | 'onChange'>;

export const TimeDurationInput = <T extends NumberType>({
  value,
  min,
  max,
  valueType,
  scale = 'ms',
  onChange = noop,
  alignInput = 'right',
  autoFormat = false,
  ...inputProps
}: TimeDurationInputProps<T>) => {
  const {
    value: strValue,
    onChange: onStrChange,
    error: violationError,
  } = useParseNumberInput({
    value,
    onChange,
    min,
    max,
    valueType,
  } as any);

  const [timeMap, setTimeMap] = useState(
    convertFromStrValue(strValue, scale),
  );

  useEffect(() => {
    setTimeMap(prev =>
      `${convertToValue(prev, scale)}` !== strValue || autoFormat
        ? convertFromStrValue(strValue, scale)
        : prev,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseInt(strValue, 10), scale]);

  const scaleIndex = scaleArr.findIndex(s => s === scale);
  return (
    <CenterRow gap={6} fullWidth>
      {scaleArr.slice(0, scaleIndex + 1).map(curScale => (
        <CenterRow key={curScale} gap={6} flex={1}>
          <NumeralInput
            error={violationError}
            onChange={val => {
              const newTimeMap = { ...timeMap, [curScale]: val };
              setTimeMap(newTimeMap);
              const newValue = convertToValue(newTimeMap, scale);
              if (!Number.isNaN(newValue)) onStrChange(`${newValue}`);
            }}
            value={timeMap[curScale]}
            valueType="number"
            alignInput={alignInput}
            {...inputProps}
            suffix={
              <Txt h2 c3>
                {scaleNameMap[curScale]}
              </Txt>
            }
            precision={4}
          />
          <Txt h3>{`${curScale !== scale ? ':' : ''}`}</Txt>
        </CenterRow>
      ))}
    </CenterRow>
  );
};

const scaleArr: ScaleType[] = ['d', 'h', 'm', 's', 'ms'];
export const convertFromStrValue = (value: string, scale: ScaleType) =>
  convertValueToTimeMap(convertValueFromScale(Number(value) ?? 0, scale));

export const convertToValue = (
  timeMap: Record<ScaleType, number>,
  scale: ScaleType,
) => convertValueToScaled(convertTimeMapToValue(timeMap), scale);

export function convertValueToTimeMap(
  value: number,
): Record<ScaleType, number> {
  const ms = Math.round(value % 1000) || 0;
  const s = Math.floor((value / 1000) % 60) || 0;
  const m = Math.floor((value / 60000) % 60) || 0;
  const h = Math.floor((value / 3600000) % 24) || 0;
  const d = Math.floor(value / 86400000) || 0;
  return {
    d,
    h,
    m,
    s,
    ms,
  };
}

export function convertTimeMapToValue(
  timeMap: Record<ScaleType, number>,
): number {
  const { d, h, m, s, ms } = timeMap;
  return (((d * 24 + h) * 60 + m) * 60 + s) * 1000 + ms;
}

export function convertValueFromScale(value: number, scale: ScaleType) {
  return value * (SCALE_CONVERSIONS[scale] || 1);
}

export function convertValueToScaled(value: number, scale: ScaleType) {
  return value / (SCALE_CONVERSIONS[scale] || 1);
}

export const SCALE_CONVERSIONS: Record<ScaleType, number> = {
  ms: 1,
  s: 1000,
  m: 60000,
  h: 3600000,
  d: 86400000,
};
