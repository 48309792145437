import styled from 'styled-components';
import { CenterRow } from '../layout';

export const Capsule = styled(CenterRow)<{ background?: string }>`
  padding: 5px 12px;
  border-radius: 30px;
  background: ${p => p.background};
`;

export const AnimatedCapsule = styled(Capsule)<{ background?: string }>`
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  background-size: 300% 300%;
  animation: gradient-animation 5s ease infinite;
`;
