/* eslint-disable prettier/prettier */
import './initialize';
// REMIND!!!: Next line insert wallet context, should be import at first line
import { HoxRoot } from '@dao/shared/deps';
import { eventEmitter } from '@dao/shared/utils';
import { UIProvider } from '@dao/ui-design';
import React from 'react';
import { history } from 'umi';
import { initWalletCtx } from '@dao/dao-api/wallet';
import { getWalletAdapter, useWalletAdapter } from '@dao/aptos-web-wallet';
import { WalletProvider } from '@dao/aptos-web-wallet/provider';
import { RegisterBlocker } from './components/RegisterBlocker';

// if (process.env.NODE_ENV !== 'development') {
//   Sentry.init({
//     dsn: 'https://df906ca0d11442a593d987f2e3eaeb87@o1121596.ingest.sentry.io/4504100316971008',
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

initWalletCtx({ hook: useWalletAdapter, getter: getWalletAdapter });

eventEmitter.on('navigate', ({ path }) => {
  history.push(path);
});

// The way to add a provider in umijs
export function rootContainer(container: React.ReactElement) {
  return (
    <HoxRoot>
      <UIProvider>
        <WalletProvider>
          <RegisterBlocker>{container}</RegisterBlocker>
        </WalletProvider>
      </UIProvider>
    </HoxRoot>
  );
}
