import { Switch } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { STYLE_PREFIX } from './constants';

export const StoryBookPage: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  css?: string;
}> = ({ children, style, className, css }) => (
  <Container style={style} className={className} css={css}>
    <Switch
      unCheckedChildren="dark"
      onChange={v => {
        document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', v ? 'dark' : 'light');
      }}
      style={{ marginBottom: 15, display: 'block' }}
    />
    {children}
  </Container>
);

const Container = styled.div<{ css: any }>`
  ${props => props.css};
  padding: 14px;
  background-color: var(--${STYLE_PREFIX}-surface-default);
  height: 100%;
  width: 100%;
`;
