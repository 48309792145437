/* eslint-disable react/no-unstable-nested-components */
import { colorConfig, globalToken, useThemeMode } from '@dao/ui-theming';
import StepImpl from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import StepperImpl from '@mui/material/Stepper';
import { isString } from 'lodash';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Icons } from '../icon';
import { Txt } from '../txt';

const { marginLG, colorIconSuccess, Step: stepStyle } = globalToken;

export const Stepper: React.FC<{
  style?: React.CSSProperties;
  steps: {
    title: ReactNode;
    content?: ReactNode;
    finished?: boolean;
    label?: ReactNode;
  }[];
  activeStep: number;
}> = ({ steps, activeStep, style }) => {
  const { colorIconSuccess } = colorConfig;
  const { mode } = useThemeMode();
  return (
    <StyledStep style={style}>
      <StepperImpl activeStep={activeStep} orientation="vertical">
        {steps.map(({ title, content, finished, label }, index) => (
          <StepImpl key={`${title?.toString()} ${index}`}>
            <StepLabel
              StepIconProps={{
                icon:
                  label ??
                  (finished ? (
                    <Icons.Success
                      style={{
                        color: colorIconSuccess[mode],
                        width: 20,
                      }}
                    />
                  ) : (
                    index + 1
                  )),
              }}
            >
              {isString(title) ? (
                <Txt h3 bold center>
                  {title}
                </Txt>
              ) : (
                title
              )}
            </StepLabel>
            <StepContent transitionDuration={100}>
              {isString(content) ? <Txt h2>{content}</Txt> : content}
            </StepContent>
          </StepImpl>
        ))}
      </StepperImpl>
    </StyledStep>
  );
};

const { colorStepBg, borderRadiusStep } = stepStyle;

const StyledStep = styled.div`
  .MuiStep-root {
    margin-bottom: ${marginLG}px;
    background: ${colorStepBg};
    border-radius: ${borderRadiusStep}px;
    padding: ${stepStyle.paddingStepContent};
  }

  .MuiStepContent-root {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
  }

  .MuiStepIcon-root {
    height: 40px;
    width: 40px;
  }
  .MuiStepLabel-root {
    padding: 0;
  }

  .MuiStepConnector-root {
    display: none;
  }
  .MuiStepIcon-root.Mui-completed {
    color: ${colorIconSuccess};
  }
  .MuiStepLabel-iconContainer {
    padding-right: 4px;
  }
  svg > circle {
    color: ${colorIconSuccess};
  }
`;
