import { globalToken } from '@dao/ui-theming';
import cx from 'classnames';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../../common';
import { Txt, TxtProps } from '../../txt';

const { colorTextBase } = globalToken;
const LoadingIconWrapper = styled(Txt)`
  position: relative;
  z-index: 0;
  animation: 2s rotation infinite linear;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    inset: 15%;
    border-radius: 50%;
    padding: 2px;
    background: conic-gradient(
      rgba(0, 0, 0, 0) 45deg,
      ${colorTextBase} 360deg
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export default function Loading(props: TxtProps) {
  return (
    <LoadingIconWrapper
      className={cx(
        `${STYLE_PREFIX}-icon-loading`,
        `${STYLE_PREFIX}-icon`,
      )}
      {...props}
    />
  );
}
