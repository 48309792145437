/* eslint-disable import/no-mutable-exports */
import { Types } from 'aptos';
import { SubmitTransactionRequest } from 'aptos/src/generated';

type Adapter = {
  walletAddress: string | undefined;
  walletIcon: string | undefined;
  walletPublicKey: string | undefined;
  validNetworkWithNotify: (networkName: string | undefined) => void;
  signAndSubmitTransaction: (
    payload: Types.TransactionPayload,
    options: Partial<SubmitTransactionRequest>,
  ) => Promise<{ hash: string; payload: any }>;
  getAddressWithNotify: () => string | undefined;
  getAddress: () => string | undefined;
  connect: () => void;
  disconnect: () => void;
};

export let useWallet: () => Adapter;

export let getWalletCtx: () => Adapter | undefined;

export const initWalletCtx = (adapter: {
  hook: typeof useWallet;
  getter: typeof getWalletCtx;
}) => {
  useWallet = adapter.hook;
  getWalletCtx = adapter.getter;
};
