import { TxnResult } from '@aries-markets/create-sdk';
import { getWalletAdapter } from '@dao/aptos-web-wallet';
import { shortenAddress } from '@dao/shared/utils';
import {
  CenterRow,
  NotificationLevel,
  Notify,
  Spin,
  Txt,
} from '@dao/ui-design';
import { get, isNumber, isString } from 'lodash';

export const isDev = () => process.env.NODE_ENV === 'development';

export function withSendTxNotify<T extends any[]>(
  fn: (...params: T) => Promise<TxnResult>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
): (...params: T) => Promise<boolean> {
  return async (...params: T) => {
    const ins = Notify.preserve(
      NotificationLevel.WARN,
      options?.sendingTitle ?? 'Sending Transaction.',
      <CenterRow style={{ display: 'inline' }}>
        Waiting for a while.
        <Spin size={15} style={{ marginLeft: 5 }} />
      </CenterRow>,
    );

    try {
      const wallet = getWalletAdapter();
      if (!wallet) throw new Error('Please connect your wallet.');

      const res = await fn(...params);
      if (res.success) {
        ins.update(
          {
            message:
              options?.sendSuccessTitle ?? 'Send Transaction Successful',
            description: options?.sendSuccessContent ?? (
              <Txt>
                Txn:{' '}
                <Txt style={{ textDecoration: 'underline' }}>
                  <a
                    target="_blank"
                    href={`https://explorer.devnet.aptos.dev/txn/${res.txId}`}
                    rel="noreferrer"
                  >
                    {`${shortenAddress(res.txId, 5)}`}
                  </a>
                </Txt>
              </Txt>
            ),
          },
          NotificationLevel.SUCCESS,
        );

        setTimeout(() => {
          ins.close();
        }, 5000);
      } else {
        throw new Error(res.message);
      }

      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Send Tx Failed]', err);
      const maybeReqErrorCode = get(err, 'response.data.code');
      let errMsg = 'Unknown Error';

      // Aptos Request Error
      if (isNumber(maybeReqErrorCode) && maybeReqErrorCode !== 200) {
        errMsg = get(err, 'response.data.message') ?? 'Unknown Error';
      } else {
        // Client Error
        errMsg =
          err instanceof Error
            ? err.message
            : isString(err)
            ? err
            : 'Unknown Error';
      }

      ins.update(
        {
          message: 'Send Transaction Failed',
          description: errMsg,
        },
        NotificationLevel.ERROR,
      );

      setTimeout(() => {
        ins.close();
      }, 5000);

      return false;
    }
  };
}

export async function withLoadingNotify<T>(
  promise: Promise<T>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
): Promise<T | null> {
  const ins = Notify.preserve(
    NotificationLevel.WARN,
    options?.sendingTitle ?? 'Sending.',
    <CenterRow style={{ display: 'inline' }}>
      Waiting for a while.
      <Spin size={15} style={{ marginLeft: 5 }} />
    </CenterRow>,
  );

  try {
    const res = await promise;
    ins.update(
      {
        message: options?.sendSuccessTitle ?? 'Success',
        description: options?.sendSuccessContent ?? '',
      },
      NotificationLevel.SUCCESS,
    );

    setTimeout(() => {
      ins.close();
    }, 2000);

    return res;
  } catch (err) {
    const errMsg = (err as any)?.message ?? 'Unknown Error';

    ins.update(
      {
        message: 'Send Transaction Failed',
        description: errMsg,
      },
      NotificationLevel.ERROR,
    );

    setTimeout(() => {
      ins.close();
    }, 5000);

    return null;
  }
}
