import {
  ComponentColorConfigMap,
  ComponentTokenMap,
  setTheme,
} from '@dao/ui-theming';

// ProgressBar
const colorProgressBarLeftBg = {
  dark: `linear-gradient(
    -45deg,
    #23a6d5,
    #23aad1,
    #23b3ca,
    #23b7c6,
    #23c0be,
    #23c4ba,
    #23ccb3,
    #23d1af,
    #23d5ab
  )`,
  light: 'rgba(27, 188, 34, 1)',
};
const colorProgressBarRightBg = {
  dark: 'transparent',
  light: 'rgba(244, 78, 78, 1)',
};
const colorSwitchThumb = {
  dark: '#ADB8DB',
  light: '#ADB8DB',
};
const colorSwitchThumbDisabled = {
  dark: '#737B94',
  light: '#737B94',
};
const colorSwitchCheckedThumb = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
const colorSwitchThumbCheckedDisabled = {
  dark: '#ADB8DB',
  light: '#ADB8DB',
};

// Table
const colorTableBg = {
  dark: '#0a0c14',
  light: '#FFFFFF',
};
const colorTheadBg = {
  dark: '#0a0c14',
  light: '#FFFFFF',
};
const colorTableBgHover = {
  dark: '#141929',
  light: '#F7F8FD',
};
const colorTableBorder = {
  dark: '#141929',
  light: '#FFFFFF',
};
const colorTheadBorder = {
  dark: 'none',
  light: 'rgba(96, 113, 168, 0.15)',
};
const colorTableBorderLeftHover = {
  dark: '#71ceff',
  light: '#EEEEEE',
};

// Switch
const colorSwitchBg = {
  dark: '#08090f',
  light: '',
};
const colorSwitchBorder = {
  dark: '#25304f',
  light: '',
};
const colorSwitchBgChecked = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9cdcff 0%, #69caff 100%)',
  light: '',
};
const colorSwitchBgCheckedDisabled = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, rgba(105, 202, 255, 0.55) 0%, rgba(156, 220, 255, 0.55) 100%)',
  light: '',
};

// Input
const colorTextInput = {
  dark: '#adb8db',
  light: '#000000',
};
const colorInputBg = {
  dark: '#141929',
  light: 'rgba(246, 247, 252, 1)',
};
const colorInputBorder = {
  dark: '#1b2238',
  light: 'transparent',
};
const colorInputLabel = {
  dark: '#adb8d8',
  light: '#232323',
};
const colorInputLabelActive = {
  dark: '#71ceff',
  light: '#1890ff',
};
const colorInputBgHover = {
  dark: '#141929',
  light: 'rgba(246, 247, 252, 1)',
};
const colorInputBorderHover = {
  dark: '#1b2238',
  light: 'rgba(212, 217, 234, 1)',
};
const colorInputBorderActiveHover = {
  dark: '#1b2238',
  light: 'rgba(212, 217, 234, 1)',
};
const colorInputBgActive = {
  dark: '#141929',
  light: '#FFFFFF',
};
const colorInputBorderActive = {
  dark: 'rgba(105, 202, 255, 0.4)',
  light: 'rgba(212, 217, 234, 1)',
};
const colorInputBgDisabled = {
  dark: '#141929',
  light: '#F6F7FC',
};
const colorInputBorderDisabled = {
  dark: '#141929',
  light: 'transparent',
};
const colorInputCriticalBoxshadow = {
  dark: 'rgba(254, 202, 202, 0.5)',
  light: '',
};
const colorInputBorderCritial = {
  dark: '#fca5a5',
  light: '#FE746D',
};

// Button
const borderWidthButton = {
  dark: '1px',
  light: '2px',
};
const colorButtonText = {
  dark: '#71ceff',
  light: 'rgba(31, 32, 36, 1)',
};
const colorButtonTextActive = {
  dark: '#0c8ed4',
  light: '',
};
const colorButtonTextDisabled = {
  dark: 'rgba(113, 206, 255, 0.6)',
  light: '',
};
// Primary
const colorButtonPrimaryText = {
  dark: '#0f121c',
  light: '#fff',
};
const colorButtonPrimaryBorder = {
  dark: 'none',
  light: 'none',
};
const colorButtonPrimaryBg = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9cdcff 0%, #69caff 100%)',
  light: 'rgba(31, 32, 36, 1)',
};
const colorButtonPrimaryBgHover = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #69CAFF 0%, #9CDCFF 100%)',
  light: 'linear-gradient(224.86deg, #147DFB 45.28%, #89E0FF 100%)',
};
const colorButtonPrimaryBgActive = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #4FC1FF 0%, #0085CC 100%)',
  light: '#147DFB',
};
const colorButtonPrimaryBgLoading = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9CDCFF 0%, #69CAFF 100%)',
  light:
    'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9CDCFF 0%, #69CAFF 100%)',
};
const colorButtonPrimaryBgDisabled = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, rgba(105, 202, 255, 0.55) 0%, rgba(156, 220, 255, 0.55) 100%)',
  light: '#8E96AE',
};
// Ghost
const colorButtonGhostTextActive = {
  dark: '#71ceff',
  light: 'rgba(113, 121, 146, 1)',
};
const colorButtonGhostTextDisabled = {
  dark: '#71ceff',
  light: 'rgba(175, 184, 208, 1)',
};
const colorButtonGhostBg = {
  dark: 'transparent',
  light: 'transparent',
};
const colorButtonGhostBgHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: 'transparent',
};
const colorButtonGhostBorder = {
  dark: '#69caff',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorButtonGhostBorderHover = {
  dark: '#9cdcff',
  light: 'rgba(96, 113, 168, 0.8)',
};
const colorButtonGhostBorderLoading = {
  dark: '#9cdcff',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorButtonGhostBorderDisabled = {
  dark: 'rgba(156, 220, 255, 0.55)',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorButtonGhostBorderActive = {
  dark: '#4fc1ff',
  light: 'rgba(96, 113, 168, 0.8)',
};
// Default
const colorButtonDefaultTextActive = {
  dark: 'rgba(255, 255, 255, 1)',
  light: 'rgba(113, 121, 146, 1)',
};
const colorButtonDefaultTextDisabled = {
  dark: 'rgba(255, 255, 255, 0.8)',
  light: 'rgba(113, 121, 146, 0.8)',
};
const colorButtonDefaultBg = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
const colorButtonDefaultBgHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#D4D9EA',
};
const colorButtonDefaultBorder = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
const colorButtonDefaultBorderHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#D4D9EA',
};
const colorButtonDefaultBorderLoading = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
const colorButtonDefaultBorderDisabled = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
const colorButtonDefaultBorderActive = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
};
// Success
const colorButtonSuccessText = {
  dark: 'rgba(255, 255, 255, 1)',
  light: '#FFFFFF',
};
const colorButtonSuccessTextActive = {
  dark: 'rgba(255, 255, 255, 1)',
  light: '#FFFFFF',
};
const colorButtonSuccessTextDisabled = {
  dark: 'rgba(255, 255, 255, 0.8)',
  light: '#FFFFFF',
};
const colorButtonSuccessBg = {
  dark: '#1BBC22',
  light: '#1BBC22',
};
const colorButtonSuccessBgHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#0DA913',
};
const colorButtonSuccessBgActive = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#06920C',
};
const colorButtonSuccessBorder = {
  dark: '#FFFFFF',
  light: '#1BBC22',
};
const colorButtonSuccessBorderHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#0DA913',
};
const colorButtonSuccessBorderLoading = {
  dark: '#FFFFFF',
  light: '#1BBC22',
};
const colorButtonSuccessBorderDisabled = {
  dark: '#FFFFFF',
  light: '#1BBC22',
};
const colorButtonSuccessBorderActive = {
  dark: '#FFFFFF',
  light: '#06920C',
};
// Critical
const colorButtonCriticalText = {
  dark: 'rgba(255, 255, 255, 1)',
  light: '#FFFFFF',
};
const colorButtonCriticalTextActive = {
  dark: 'rgba(255, 255, 255, 1)',
  light: '#FFFFFF',
};
const colorButtonCriticalTextDisabled = {
  dark: 'rgba(255, 255, 255, 0.8)',
  light: '#FFFFFF',
};
const colorButtonCriticalBg = {
  dark: '#FFFFFF',
  light: '#F44E4E',
};
const colorButtonCriticalBgHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#E33F3F',
};
const colorButtonCriticalBgActive = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: 'CE3636',
};
const colorButtonCriticalBorder = {
  dark: '#FFFFFF',
  light: '#F44E4E',
};
const colorButtonCriticalBorderHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: '#E33F3F',
};
const colorButtonCriticalBorderLoading = {
  dark: '#FFFFFF',
  light: '#F44E4E',
};
const colorButtonCriticalBorderDisabled = {
  dark: '#FFFFFF',
  light: '#F44E4E',
};
const colorButtonCriticalBorderActive = {
  dark: '#FFFFFF',
  light: 'CE3636',
};

const colorButtonLinkBg = {
  dark: 'transparent',
  light: 'transparent',
};
const colorButtonLinkBgHover = {
  dark: 'rgba(113, 206, 255, 0.2)',
  light: 'rgba(113, 206, 255, 0.2)',
};

// Slider
const colorSliderRail = {
  dark: '#25304f',
  light: '#141929',
};
const colorSliderTrack = {
  dark: 'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9cdcff 0%, #69caff 100%)',
  light:
    'radial-gradient(98.65% 1226.88% at 9.19% 8.11%, #9cdcff 0%, #69caff 100%)',
};
const colorSliderThumb = {
  dark: '#ffffff',
  light: '#ffffff',
};
const colorSliderMark = {
  dark: '#ffffff',
  light: '#ffffff',
};
const colorSliderThumbBorder = {
  dark: '#ffffff',
  light: '#ffffff',
};

// Modal
const colorModalBg = {
  dark: '#0f131f',
  light: '#ffffff',
};
const colorModalBorder = {
  dark: '#141929',
  light: 'none',
};
const colorModalHeaderBg = {
  dark: '#0A0C14',
  light: '#ffffff',
};

// Notification
const colorNotificationBg = {
  dark: '#0a0c14',
  light: 'rgba(31, 32, 36, 1)',
};
const colorNotificationInfoTitle = {
  dark: '#ffffff',
  light: '#147DFB',
};
const colorNotificationWarningTitle = {
  dark: '#ffffff',
  light: 'rgba(244, 147, 0, 1)',
};
const colorNotificationSuccessTitle = {
  dark: '#ffffff',
  light: 'rgba(27, 188, 34, 1)',
};
const colorNotificationErrorTitle = {
  dark: '#ffffff',
  light: 'rgba(244, 78, 78, 1)',
};
const colorNotificationContent = {
  dark: '#adb8d8',
  light: '#ffffff',
};
const colorNotificationCloseBgHover = {
  dark: 'transparent',
  light: 'rgba(255, 255, 255, 0.05)',
};

const colorNotificationClose = {
  dark: '#737b94',
  light: '#ffffff',
};

// Step
const colorStepBg = {
  dark: '#0A0C14',
  light: 'rgba(255, 255, 255, 1)',
};

// TabView
const colorTabIndicator = {
  dark: 'rgb(98, 108, 241)',
  light: '#688EEC',
};

// ActionPopover
const colorBgAcPop = {
  dark: '#121212',
  light: '#ffffff',
};
const colorTextAcPopItemTitle = {
  dark: '#ffffff',
  light: '#ccc',
};
const colorSeparatorAcPop = {
  dark: '#ccc',
  light: '#ccc',
};
export const componentTokenMap: ComponentTokenMap = {
  TokenIcon: {
    borderRadiusTokenIcon: '50%',
  },
  ProgressBar: {
    borderRadiusProgressBar: 4,
    colorProgressBarLeftBg: setTheme(colorProgressBarLeftBg),
    colorProgressBarRightBg: setTheme(colorProgressBarRightBg),
  },
  Table: {
    paddingHeadCellVertical: 8,
    paddingHeaderCellHorizontal: 16,
    paddingDataCellVertial: 16,
    paddingDataCellHorizontal: 16,
    paddingRowVertical: 8,
    paddingRowHorizontal: 24,
    borderRadiusTable: 12,
    borderRadiusTableRow: 12,
    borderWidthTheadTop: 0,
    borderWidthTheadRight: 0,
    borderWidthTheadBottom: 0,
    borderWidthTheadLeft: 0,
    colorTableBg: setTheme(colorTableBg),
    colorTheadBg: setTheme(colorTheadBg),
    colorTableBgHover: setTheme(colorTableBgHover),
    colorTheadBorder: setTheme(colorTheadBorder),
    colorTableBorder: setTheme(colorTableBorder),
    colorTableBorderLeftHover: setTheme(colorTableBorderLeftHover),
  },
  Switch: {
    borderRadiusSwitchTrack: 20,
    borderRadiusSwitchThumb: 16,
    colorSwitchBg: setTheme(colorSwitchBg),
    colorSwitchBorder: setTheme(colorSwitchBorder),
    colorSwitchBgChecked: setTheme(colorSwitchBgChecked),
    colorSwitchBgCheckedDisabled: setTheme(colorSwitchBgCheckedDisabled),
    colorSwitchThumb: setTheme(colorSwitchThumb),
    colorSwitchThumbDisabled: setTheme(colorSwitchThumbDisabled),
    colorSwitchCheckedThumb: setTheme(colorSwitchCheckedThumb),
    colorSwitchThumbCheckedDisabled: setTheme(
      colorSwitchThumbCheckedDisabled,
    ),
  },
  Input: {
    paddingInput: '12px',
    heightInput: '44px',
    borderWidthInput: 2,
    borderRadiusInput: '12px',
    colorTextInput: setTheme(colorTextInput),
    boxShadowInputActive: '0px 0px 0px 2px #F1F4FD',
    colorInputBg: setTheme(colorInputBg),
    colorInputBorder: setTheme(colorInputBorder),
    colorInputLabel: setTheme(colorInputLabel),
    colorInputLabelActive: setTheme(colorInputLabelActive),
    colorInputBgHover: setTheme(colorInputBgHover),
    colorInputBorderHover: setTheme(colorInputBorderHover),
    colorInputBorderActiveHover: setTheme(colorInputBorderActiveHover),
    colorInputBgActive: setTheme(colorInputBgActive),
    colorInputBorderActive: setTheme(colorInputBorderActive),
    colorInputBgDisabled: setTheme(colorInputBgDisabled),
    colorInputBorderDisabled: setTheme(colorInputBorderDisabled),
    colorInputCriticalBoxshadow: setTheme(colorInputCriticalBoxshadow),
    colorInputBorderCritial: setTheme(colorInputBorderCritial),
  },
  Button: {
    opacityButtonDisabled: 0.8,
    borderWidthButton: setTheme(borderWidthButton),
    heightButtonSM: 40,
    heightButtonLG: 48,
    paddingHorizontalButtonSM: 16,
    paddingHorizontalButtonLG: 24,
    colorButtonText: setTheme(colorButtonText),
    colorButtonTextActive: setTheme(colorButtonTextActive),
    colorButtonTextDisabled: setTheme(colorButtonTextDisabled),
    colorButtonPrimaryText: setTheme(colorButtonPrimaryText),
    colorButtonPrimaryBorder: setTheme(colorButtonPrimaryBorder),
    colorButtonPrimaryBg: setTheme(colorButtonPrimaryBg),
    colorButtonPrimaryBgHover: setTheme(colorButtonPrimaryBgHover),
    colorButtonPrimaryBgActive: setTheme(colorButtonPrimaryBgActive),
    colorButtonPrimaryBgLoading: setTheme(colorButtonPrimaryBgLoading),
    colorButtonPrimaryBgDisabled: setTheme(colorButtonPrimaryBgDisabled),
    colorButtonGhostTextActive: setTheme(colorButtonGhostTextActive),
    colorButtonGhostTextDisabled: setTheme(colorButtonGhostTextDisabled),
    colorButtonGhostBg: setTheme(colorButtonGhostBg),
    colorButtonGhostBgHover: setTheme(colorButtonGhostBgHover),
    colorButtonGhostBorder: setTheme(colorButtonGhostBorder),
    colorButtonGhostBorderHover: setTheme(colorButtonGhostBorderHover),
    colorButtonGhostBorderLoading: setTheme(colorButtonGhostBorderLoading),
    colorButtonGhostBorderDisabled: setTheme(
      colorButtonGhostBorderDisabled,
    ),
    colorButtonGhostBorderActive: setTheme(colorButtonGhostBorderActive),
    colorButtonDefaultTextDisabled: setTheme(
      colorButtonDefaultTextDisabled,
    ),
    colorButtonDefaultTextActive: setTheme(colorButtonDefaultTextActive),
    colorButtonDefaultBg: setTheme(colorButtonDefaultBg),
    colorButtonDefaultBgHover: setTheme(colorButtonDefaultBgHover),
    colorButtonDefaultBorder: setTheme(colorButtonDefaultBorder),
    colorButtonDefaultBorderHover: setTheme(colorButtonDefaultBorderHover),
    colorButtonDefaultBorderLoading: setTheme(
      colorButtonDefaultBorderLoading,
    ),
    colorButtonDefaultBorderDisabled: setTheme(
      colorButtonDefaultBorderDisabled,
    ),
    colorButtonDefaultBorderActive: setTheme(
      colorButtonDefaultBorderActive,
    ),
    colorButtonSuccessTextDisabled: setTheme(
      colorButtonSuccessTextDisabled,
    ),
    colorButtonSuccessText: setTheme(colorButtonSuccessText),
    colorButtonSuccessTextActive: setTheme(colorButtonSuccessTextActive),
    colorButtonSuccessBg: setTheme(colorButtonSuccessBg),
    colorButtonSuccessBgHover: setTheme(colorButtonSuccessBgHover),
    colorButtonSuccessBgActive: setTheme(colorButtonSuccessBgActive),
    colorButtonSuccessBorder: setTheme(colorButtonSuccessBorder),
    colorButtonSuccessBorderHover: setTheme(colorButtonSuccessBorderHover),
    colorButtonSuccessBorderLoading: setTheme(
      colorButtonSuccessBorderLoading,
    ),
    colorButtonSuccessBorderDisabled: setTheme(
      colorButtonSuccessBorderDisabled,
    ),
    colorButtonSuccessBorderActive: setTheme(
      colorButtonSuccessBorderActive,
    ),
    colorButtonCriticalTextDisabled: setTheme(
      colorButtonCriticalTextDisabled,
    ),
    colorButtonCriticalText: setTheme(colorButtonCriticalText),
    colorButtonCriticalTextActive: setTheme(colorButtonCriticalTextActive),
    colorButtonCriticalBg: setTheme(colorButtonCriticalBg),
    colorButtonCriticalBgHover: setTheme(colorButtonCriticalBgHover),
    colorButtonCriticalBgActive: setTheme(colorButtonCriticalBgActive),
    colorButtonCriticalBorder: setTheme(colorButtonCriticalBorder),
    colorButtonCriticalBorderHover: setTheme(
      colorButtonCriticalBorderHover,
    ),
    colorButtonCriticalBorderLoading: setTheme(
      colorButtonCriticalBorderLoading,
    ),
    colorButtonCriticalBorderDisabled: setTheme(
      colorButtonCriticalBorderDisabled,
    ),
    colorButtonCriticalBorderActive: setTheme(
      colorButtonCriticalBorderActive,
    ),
    colorButtonLinkBg: setTheme(colorButtonLinkBg),
    colorButtonLinkBgHover: setTheme(colorButtonLinkBgHover),
  },
  Slider: {
    colorSliderRail: setTheme(colorSliderRail),
    colorSliderTrack: setTheme(colorSliderTrack),
    colorSliderThumb: setTheme(colorSliderThumb),
    colorSliderMark: setTheme(colorSliderMark),
    colorSliderThumbBorder: setTheme(colorSliderThumbBorder),
  },
  Modal: {
    widthCloseButton: 20,
    heightCloseButton: 20,
    borderRadiusModal: 24,
    paddingModal: '34px 32px 32px',
    paddingModalHeader: 0,
    paddingModalContent: 0,
    colorModalBg: setTheme(colorModalBg),
    colorModalBorder: setTheme(colorModalBorder),
    colorModalHeaderBg: setTheme(colorModalHeaderBg),
  },
  Notification: {
    showProgressBar: true,
    paddingNotification: '22px 18px',
    borderRadiusNotification: 16,
    borderRadiusNotificationClose: 8,
    paddingNotificationClose: 10,
    colorNotificationClose: setTheme(colorNotificationClose),
    colorNotificationCloseBgHover: setTheme(colorNotificationCloseBgHover),
    sizeHeadingIcon: '20px',
    widthNotification: '360px',
    colorNotificationBg: setTheme(colorNotificationBg),
    colorNotificationInfoTitle: setTheme(colorNotificationInfoTitle),
    colorNotificationWarningTitle: setTheme(colorNotificationWarningTitle),
    colorNotificationSuccessTitle: setTheme(colorNotificationSuccessTitle),
    colorNotificationErrorTitle: setTheme(colorNotificationErrorTitle),
    colorNotificationContent: setTheme(colorNotificationContent),
  },
  Step: {
    paddingStepContent: '24px',
    colorStepBg: setTheme(colorStepBg),
    borderRadiusStep: 24,
  },
  TabView: {
    gapTabsContainer: 24,
    colorTabIndicator: setTheme(colorTabIndicator),
  },
  ActionPopover: {
    paddingVerticalPAcpop: 8,
    paddingHorizontalAcpop: 8,
    directionInsetsAcpop: 4,
    paddingVerticalAcPopItem: 6,
    paddingHorizontalAcPopItem: 10,
    colorBgAcPop: setTheme(colorBgAcPop),
    colorTextAcPopItemTitle: setTheme(colorTextAcPopItemTitle),
    colorSeparatorAcPop: setTheme(colorSeparatorAcPop),
  },
  Select: {
    borderRadiusSelectMenuItem: 12,
    borderRadiusSelectMenuRoot: 16,
    marginTopSelectMenu: 4,
  },
};

export const componentColorConfigMap: ComponentColorConfigMap = {
  Modal: {
    colorModalBg,
    colorModalBorder,
    colorModalHeaderBg,
  },
  Slider: {
    colorSliderRail,
    colorSliderTrack,
    colorSliderThumb,
    colorSliderMark,
    colorSliderThumbBorder,
  },
  Switch: {
    colorSwitchBg,
    colorSwitchBorder,
    colorSwitchBgChecked,
    colorSwitchBgCheckedDisabled,
    colorSwitchThumb,
    colorSwitchCheckedThumb,
    colorSwitchThumbDisabled,
    colorSwitchThumbCheckedDisabled,
  },
};
