import { useCallback, useEffect, useState } from 'react';

export type Subscriber<T> = (current: T) => void;
export type SubscriberId = number;

export interface Subscribable<T> {
  sub: SubHandler<T>;
}

export interface SubHandler<T> {
  getCurrent: () => T;
  subscribe: (onChange: Subscriber<T>) => SubscriberId;
  unsubscribe: (subId: SubscriberId) => void;
  publishUpdate: () => void;
}

/**
 * Create a subscribable handler for mutable states
 * @param get returns latest mutable value
 * @param mut mutates the state
 * @returns [wrappedGet, wrappedMut, subHandler]
 */
export function createSubscribable<T, MutArgs extends Array<any>, MutRet>(
  get: () => T,
  mut: (...args: MutArgs) => MutRet,
): [
  () => T, // get current value
  (...args: MutArgs) => MutRet, // wrapped mutation handler
  SubHandler<T>, // the subscription handler
] {
  const subscribers: Map<SubscriberId, Subscriber<T>> = new Map();
  let subscriberCount: SubscriberId = 0;
  const subscribe = (cb: Subscriber<T>) => {
    const subId = subscriberCount++;
    subscribers.set(subId, cb);
    return subId;
  };
  const unsubscribe = (subId: SubscriberId) => {
    subscribers.delete(subId);
  };
  const publish = () => {
    const currentValue = get();
    subscribers.forEach(cb => cb(currentValue));
  };

  const wrappedMut = (...args: MutArgs) => {
    const ret = mut(...args);
    publish();
    return ret;
  };

  const sub: SubHandler<T> = {
    getCurrent: get,
    subscribe,
    unsubscribe,
    publishUpdate: publish,
  };

  return [get, wrappedMut, sub];
}

/** A plain and simple hooks to force an update */
function useRerender() {
  const [, setRevision] = useState<number>(0);
  const update = useCallback(() => setRevision(rev => rev + 1), []);
  return update;
}

export function useMutationObserver<T>(sub: SubHandler<T>): T {
  const [value, setValue] = useState<T>(sub.getCurrent());
  const forceUpdate = useRerender();

  useEffect(() => {
    const subId = sub.subscribe((current: T) => {
      setValue(current);
      forceUpdate();
    });
    return () => sub.unsubscribe(subId);
  }, [sub, forceUpdate]);
  return value;
}
