import { ColorToken, globalToken } from '@dao/ui-theming';
import { memoize } from 'lodash';

export const STYLE_PREFIX = 'aries-design';
export const UI_TRANSITION = `var(--${STYLE_PREFIX}-transition)`;
export const UI_TRANSITION_SLOW = `var(--${STYLE_PREFIX}-transition-slow)`;

const portColorInner = memoize(
  (name: string) =>
    `var(--${STYLE_PREFIX}-${name.split('/').join('-').toLowerCase()})`,
);

/**
 * example:
 *  Surface/Default => suface-default
 *  Interactive/Default, 50 => interactive-default-a50
 *  */
export const portColor = (name: string, alpha?: number) =>
  portColorInner(name + (alpha !== undefined ? `-a${alpha}` : ''));

export const designColor = (name?: keyof ColorToken) =>
  name && globalToken[name];
