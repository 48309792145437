/* eslint-disable no-nested-ternary */
import { globalToken } from '@dao/ui-theming';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const { marginXS, colorBgDefault } = globalToken;
const { borderRadiusProgressBar } = globalToken.ProgressBar;

export const ProgressBar: React.FC<{
  pct: number;
  align?: 'center' | 'left' | 'right';
  style?: React.CSSProperties;
  subBarStyle?: React.CSSProperties;
  colorLeft?: string;
  colorRight?: string;
}> = ({
  pct,
  align = 'left',
  style,
  colorLeft,
  colorRight,
  subBarStyle,
}) => {
  const [animatedPct, setPct] = useState(0);
  useEffect(() => {
    setPct(pct);
  }, [pct]);

  return (
    <BarContainer
      style={{
        justifyContent:
          align === 'left'
            ? 'flex-start'
            : align === 'right'
            ? 'flex-end'
            : 'center',
        ...style,
      }}
    >
      <Bar
        style={{
          width: `${animatedPct}%`,
          background: colorLeft,
          ...subBarStyle,
        }}
        isLeft={1}
      />
      <Bar
        style={{
          width: `${100 - animatedPct}%`,
          background: colorRight,
          ...subBarStyle,
        }}
      />
    </BarContainer>
  );
};

const Bar = styled.div<{ isLeft?: 1 | 0 }>`
  height: 8px;
  width: 100%;

  border-radius: ${borderRadiusProgressBar}px;
  transition: width 0.6s;
  background-size: 700% 100% !important;
  background: ${p =>
    p.isLeft
      ? globalToken.ProgressBar.colorProgressBarLeftBg
      : globalToken.ProgressBar.colorProgressBarRightBg};

  @keyframes mymove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  animation: mymove 3s linear infinite;
  -webkit-animation: mymove 3s linear infinite;
  -moz-animation: mymove 3s linear infinite;
`;

const BarContainer = styled.div`
  height: 8px;
  width: 100%;
  background: ${colorBgDefault};
  border-radius: ${borderRadiusProgressBar}px;
  margin: ${marginXS}px 0px;
  display: flex;
  justify-content: center;
`;
