import React, { useState } from 'react';
import { useLocalStorageState } from '../util';
import { NotificationBase, NotificationBaseProps } from './base';

/**
 * @param storageKey use to save the closed state
 */
const PersistantNotificationBar: React.FC<
  Omit<NotificationBaseProps, 'onClose'> & { storageKey?: string }
> = ({ storageKey, ...baseProps }) => {
  const [forgetFlag, setForgetFlag] = useLocalStorageState(
    storageKey
      ? `NOTIFICATION_STATE:${storageKey}`
      : 'NOTIFICATION_STATE:NONE',
  );
  const [show, setShow] = useState(() => forgetFlag !== '1');

  const closeNotification = () => {
    setShow(false);
    if (storageKey) {
      setForgetFlag('1');
    }
  };

  if (!show) return null;

  return (
    <NotificationBase
      onClose={() => {
        closeNotification();
      }}
      {...baseProps}
    />
  );
};

export const NotificationBar: React.FC<
  NotificationBaseProps & { storageKey?: string; display?: boolean }
> = ({ display = true, storageKey, ...props }) => {
  if (storageKey) {
    return (
      <PersistantNotificationBar storageKey={storageKey} {...props} />
    );
  }

  return display ? <NotificationBase {...props} /> : null;
};
