import { globalToken } from '@dao/ui-theming';
import cx from 'classnames';
import { isString } from 'lodash';
import {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { STYLE_PREFIX } from '../common';
import { Txt } from '../txt';
import {
  AlignInputOpt,
  InputBox,
  InputLabel,
  InputWrapper,
} from './common';
import { TextArea } from './textarea';

const { marginSM, colorTextTertiary } = globalToken;

const PureInput = styled.input<{
  align?: AlignInputOpt;
  disabled?: boolean;
}>`
  border: none;
  background: transparent;
  outline: none;
  text-align: ${p => p.align};
  flex: ${p => (p.align === 'center' ? undefined : 1)};
  ::-webkit-input-placeholder {
    color: ${colorTextTertiary};
  }

  ::placeholder {
    color: ${colorTextTertiary};
  }

  &:hover {
    cursor: ${p => (p.disabled ? 'not-allowed' : 'text')};
  }
`;

export type InputProps = {
  // dispaly contents
  value?: string;
  label?: ReactNode;
  suffix?: ReactNode;
  prefix?: ReactNode;
  placeholder?: string;
  autoFocus?: boolean;

  // callbacks
  onChange?: (input: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onEnterPress?: () => void;

  // customizations
  className?: string;
  disabled?: boolean;
  error?: boolean | Error | string;
  /** input alignment option (effective at block mode):
   *  - center: centering prefix, input and suffix, input will take space as its content requires
   *  - right: make the input flex and align input text to the right, suitale for number input
   *  - left(default): make the input flex and align input text left, suitable for text input
   */
  alignInput?: AlignInputOpt;
  inline?: boolean;
  borderless?: boolean;
  inputStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  maxLength?: number;
};

export function Input({
  value,
  maxLength,
  label,
  prefix,
  suffix,
  onChange,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onEnterPress,
  error,
  disabled = false,
  alignInput = 'left',
  inline = false,
  borderless = false,
  inputStyle,
  wrapperStyle,
  className,
  placeholder,
  autoFocus,
}: InputProps) {
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputWrapper
      inline={inline}
      className={cx(
        `${STYLE_PREFIX}-input-root`,
        disabled ? `${STYLE_PREFIX}-input-root-disabled` : null,
        className,
      )}
      disabled={disabled}
    >
      {label && <InputLabel focused={focused}>{label}</InputLabel>}
      <InputBox
        onClick={() => !disabled && inputRef.current?.focus()}
        className={cx(`${STYLE_PREFIX}-input-box`, {
          error,
          disabled,
          focused,
        })}
        {...{
          disabled,
          focused,
          alignInput,
          inline,
          borderless,
        }}
        error={!!error}
        style={wrapperStyle}
      >
        {prefix && <Txt c2>{prefix}</Txt>}
        <PureInput
          value={value}
          onChange={e => {
            !disabled && onChange?.(e.target.value.slice(0, maxLength));
          }}
          placeholder={placeholder}
          className={`${STYLE_PREFIX}-input-inner`}
          ref={inputRef}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            onFocus?.();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur?.();
          }}
          size={value ? Math.ceil(0.7 * value.length) : undefined}
          align={alignInput}
          style={inputStyle}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onEnterPress?.();
            }
          }}
          {...{ onMouseEnter, onMouseLeave }}
        />
        {suffix &&
          (isString(suffix) ? (
            <Txt c2 ml={marginSM}>
              {suffix}
            </Txt>
          ) : (
            suffix
          ))}
      </InputBox>
    </InputWrapper>
  );
}

Input.TextArea = TextArea;
