import { globalToken } from '@dao/ui-theming';
import { isString } from 'lodash';
import toArray from 'rc-util/lib/Children/toArray';
import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { Col } from '../layout';
import { Txt } from '../txt';
import { FormItem } from './FormItem';

export const Form = ({
  children,
  style,
  contentStyle,
  titleStyle,
  title,
  helpIcon,
}: {
  children?: ReactNode;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  title?: ReactNode;
  helpIcon?: ReactNode;
}) => {
  const getItems = () =>
    toArray(children).map((child: React.ReactElement, index) => {
      const key = `${child.key ?? index}`;

      const props = {
        key,
        helpIcon,
        ...child.props,
      };

      return React.cloneElement(child, props);
    });
  return (
    <StyledForm style={style}>
      {title &&
        (isString(title) ? (
          <Txt h4 mb={24} bold style={titleStyle}>
            {title}
          </Txt>
        ) : (
          title
        ))}
      <Col style={{ gap: 16, ...contentStyle }}>{getItems()}</Col>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: ${globalToken.borderRadiusLG}px;
  background-color: ${globalToken.colorBg};
`;

Form.Item = FormItem;
